import React, { useState,useEffect } from "react";
import Sidebar from "./components/Sidebar";
import ActionPanel from "./components/ActionPanel";
import CommandPanel from "./components/CommandPanel";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { motion } from "framer-motion"
import axios from './axiosConfig';
import { useNavigate, useLocation, useParams } from "react-router-dom";


const AppLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isActionPanelOpen, setIsActionPanelOpen] = useState(false);
  const [tabs, setTabs] = useState([{ id: 1, title: "Tab 1", content: "" }]);
  const [activeTabId, setActiveTabId] = useState(1);
  // const [uploadedFile, setUploadedFile] = useState(null); // Store the uploaded file
  const [uploadedFiles, setUploadedFiles] = useState([]); // Store multiple uploaded files

  const [textData, setTextData] = useState(null); // Store text, summary, and draft
  const [internalDocuments, setInternalDocuments] = useState([]); // Internal documents state
  const [extractedData,setExtractedData] = useState([])
  const [loading, setLoading] = useState(false); // Add loading state
  const [activeSubTab, setActiveSubTab] = useState("Reference");
  const [hasChatContent, setHasChatContent] = useState(false); // ✅ Track chat status
  const [isFadingOut,setIsFadingOut] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [hasFetched, setHasFetched] = useState(false); // ✅ New state to track fetch status
  const [hasUserModified, setHasUserModified] = useState(false); // ✅ New state
  const [messages, setMessages] = useState([]); // ✅ Store messages at parent level
  const [drafts, setDrafts] = useState([]); // ✅ Store all drafts
  const [activeDraft, setActiveDraft] = useState(null);
  const [selectedMode, setSelectedMode] = useState("chat"); // default to "chat"





  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const authToken = localStorage.getItem("authToken");


const handleSaveInternalDocuments = async (documents) => {
  console.log("📝 handleSaveInternalDocuments TRIGGERED with documents:", documents);
  const projectId = sessionStorage.getItem("projectId"); // 🔥 Get projectId dynamically

  if (!projectId || !documents || documents.length === 0) return;

  try {
    console.log("📤 Saving Internal Documents:", JSON.stringify(documents, null, 2));

    await axios.post(
      `/api/projects/${projectId}/save_internal_documents`,
      { internal_documents: documents },
      { headers: { Authorization: `Bearer ${authToken}`, "Content-Type": "application/json" } }
    );

    console.log("✅ Internal Documents Saved Successfully");
  } catch (error) {
    console.error("🚨 Error saving internal documents:", error.response?.data || error.message);
  }
};


useEffect(() => {
  console.log("📌 [AppLayout] Active Draft:", activeDraft);
  console.log("📌 [AppLayout] Drafts:", drafts);
}, [activeDraft, drafts]);


const updateInternalDocuments = (documents) => {
  setInternalDocuments((prevDocuments) => {
    const uniqueDocsMap = new Map();

    // ✅ Store previous documents first
    prevDocuments.forEach((doc) => {
      uniqueDocsMap.set(`${doc.title}-${doc.content}`, doc);
    });

    // ✅ Add only new, non-duplicate documents
    documents.forEach((doc) => {
      uniqueDocsMap.set(`${doc.title}-${doc.content}`, doc);
    });

    const updatedDocuments = Array.from(uniqueDocsMap.values());

    console.log("📌 Optimized Internal Documents:", updatedDocuments);

    return updatedDocuments;
  });

  // ✅ Now call handleSaveInternalDocuments() **AFTER** state update
  setTimeout(() => {
    handleSaveInternalDocuments(documents);
  }, 50);
};


// ✅ Extract `projectId` from URL or fallback to localStorage
const urlProjectId = params.projectId || location.pathname.split("/project/")[1] || null;
const [projectId, setProjectId] = useState(urlProjectId || null);

useEffect(() => {
  console.log("AppLayout.js Project ID (SessionStorage):", sessionStorage.getItem("projectId"));
}, []);

// ✅ Ensure projectId updates correctly when the URL changes
useEffect(() => {
  if (urlProjectId) {
    if (urlProjectId !== projectId) {
      console.log("🔄 Updating projectId from URL:", urlProjectId);
      setProjectId(urlProjectId);
      sessionStorage.setItem("projectId", urlProjectId);
    }
  } else {
    // ✅ If no projectId in URL, ensure sessionStorage is cleared
    console.log("🛑 No projectId in URL. Clearing stored ID.");
    setProjectId(null);
    sessionStorage.removeItem("projectId");
  }
}, [urlProjectId]);



useEffect(() => {
  console.log("📌 Current `projectId` in state:", projectId);
}, [projectId]);

useEffect(() => {
  console.log("📌 Current Drafts in state:", drafts);
}, [drafts]);

useEffect(() => {
  console.log("📌 Current `textData.draft` in state:", textData?.draft);
}, [textData]);

useEffect(() => {
  if (!projectId || projectId === "null") {
    console.log("🛑 No valid projectId. Skipping internal document fetch.");
    setInternalDocuments([]); // ✅ Ensure old internal documents are cleared
    return;
  }

  console.log(`📡 Fetching Internal Documents for Project ID: ${projectId}`);

  const fetchInternalDocuments = async () => {
    try {
      setIsFetching(true);
      setHasFetched(false);

      const response = await axios.get(`/api/projects/${projectId}/internal_documents`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      const fetchedDocuments = response.data.internalDocuments || [];

      if (fetchedDocuments.length === 0) {
        console.warn("⚠️ No Internal Documents Found! (Check Backend)");
      } else {
        console.log("✅ Documents Fetched Successfully:", fetchedDocuments);
      }

      setInternalDocuments(fetchedDocuments); // ✅ Only set new data
      setHasFetched(true);
    } catch (error) {
      console.error("🚨 Error fetching internal documents:", error.response?.data || error.message);
    } finally {
      setIsFetching(false);
    }
  };

  // ✅ Delay fetching to ensure `projectId` is correct
  setTimeout(() => {
    fetchInternalDocuments();
  }, 50);
}, [projectId]); // ✅ Runs when `projectId` changes



useEffect(() => {
  if (urlProjectId && urlProjectId !== projectId) {
      console.log("🔄 Switching to new project, clearing drafts...");
      sessionStorage.removeItem("drafts");
      sessionStorage.removeItem("activeDraft");
      sessionStorage.removeItem("renderedDraft");
      sessionStorage.removeItem("lastProcessedDraft");

      setProjectId(urlProjectId);
      sessionStorage.setItem("projectId", urlProjectId);
  }
}, [urlProjectId]);

useEffect(() => {
  if (!projectId) return;
  setTimeout(fetchDrafts, 50);
}, [projectId]);



const fetchDrafts = async () => {
  try {
    console.log(`📡 Fetching Drafts for Project ID: ${projectId}`);

    const response = await axios.get(`/api/projects/${projectId}/drafts`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });

    const fetchedDrafts = response.data.drafts || [];

    if (fetchedDrafts.length > 0) {
      setDrafts(fetchedDrafts); // Save all drafts in global state
    
      // Set the most recent draft as active (latest by timestamp)
      const latestDraft = fetchedDrafts[fetchedDrafts.length - 1];
      setActiveDraft(latestDraft.id);
    
      console.log("✅ Drafts fetched:", fetchedDrafts.length);
      console.log("✅ Active Draft Set:", latestDraft.id);
    }else {
      console.warn("⚠️ No drafts found for this project.");
    }
  } catch (error) {
    console.error("🚨 Error fetching drafts:", error.response?.data || error.message);
  }
};



// ✅ Function to Save Drafts to Backend
const handleSaveDrafts = async (drafts) => {
  if (!projectId || !drafts || drafts.length === 0) return;

  try {
    console.log("📤 Saving Drafts:", JSON.stringify(drafts, null, 2));
    
    await axios.post(
      `/api/projects/${projectId}/save_drafts`,
      { drafts },
      { headers: { Authorization: `Bearer ${authToken}`, "Content-Type": "application/json" } }
    );

    console.log("✅ Drafts Saved Successfully");
  } catch (error) {
    console.error("🚨 Error saving drafts:", error.response?.data || error.message);
  }
};

// ✅ Function to Update Drafts State & Save to Backend
const updateDrafts = (newDrafts) => {
  setDrafts((prevDrafts) => {
    const uniqueDraftsMap = new Map();

    prevDrafts.forEach((draft) => {
      uniqueDraftsMap.set(draft.id, draft);
    });

    newDrafts.forEach((draft) => {
      uniqueDraftsMap.set(draft.id, draft);
    });

    const updatedDrafts = Array.from(uniqueDraftsMap.values());

    console.log("📌 Optimized Drafts (merged):", updatedDrafts);

    // ✅ Save merged drafts immediately
    setTimeout(() => {
      handleSaveDrafts(updatedDrafts);  // use the merged one
    }, 50);

    return updatedDrafts; // update React state too
  });
};




  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleActionPanel = () => {
    setIsActionPanelOpen(!isActionPanelOpen);
  };

  const handleAddTab = () => {
    const newTabId = tabs.length + 1;
    setTabs([...tabs, { id: newTabId, title: `Tab ${newTabId}`, content: "" }]);
    setActiveTabId(newTabId);
    setHasChatContent(false); // ✅ Reset chat content when adding a new tab
  };

  // const handleClearUploadedFile = () => {
  //   setUploadedFile(null); // Clear the uploaded file
  // };

  const handleClearUploadedFile = (indexToRemove = null) => {
    if (indexToRemove !== null) {
      // Remove just one file
      setUploadedFiles((prev) => prev.filter((_, i) => i !== indexToRemove));
    } else {
      // Clear all files
      setUploadedFiles([]);
    }
  };
  
  


  useEffect(() => {
    console.log("Loading updated:", loading);
  }, [loading]);

  const handleCloseTab = (id) => {
    const remainingTabs = tabs.filter((tab) => tab.id !== id);
    setTabs(remainingTabs);
    if (remainingTabs.length > 0) {
      setActiveTabId(remainingTabs[0].id);
    } else {
      setActiveTabId(-1);
    }
  };

  const updateChat = (chatResponse) => {
    // Send the chat response to the CommandPanel
    if (chatResponse.trim() !== "") {
      setHasChatContent(true); // ✅ Mark chat as active
      setIsSidebarOpen(false); // ✅ Auto-close sidebar when no chat


        // ✅ Only open ActionPanel for modes that aren't 'chat'
        if (selectedMode !== "chat") {
          setIsActionPanelOpen(true);
        }
    
        // ✅ Optionally auto-close sidebar on modes that aren't chat
        if (selectedMode !== "chat") {
          setIsSidebarOpen(false);
        }
      }
    setTabs((prevTabs) =>
      prevTabs.map((tab) =>
        tab.id === activeTabId
          ? { ...tab, content: `${tab.content}\n\nBot: ${chatResponse}` }
          : tab
      )
    );
  };

  
  
  const handleCommandFromPanel = async (command, tabId, commandType) => {
    try {
      console.log("Loading state in Before setLoading true:", loading);
      setLoading(true);
      console.log("Loading state in After setLoading true:", loading);
  
      let response;
  

      const apiMapping = {
        analyze: "agent-analyze",
        adjust: "adjust-draft",
        chat: "agent-chat",
        law_search: "agent-query",
        case_search: "agent-query",
        draft: "agent-draft",
      };

      setIsSidebarOpen(false)
  
      //////////////////////////////// // CASE 1 ANALYZE: 🔥 
      if (commandType === "analyze") {
        const formData = new FormData();
        formData.append("file", command.get("file"));
        if (command.get("query")) {
          formData.append("query", command.get("query"));
        }

        setIsActionPanelOpen(true)
            // ✅ **Validate Command Inputs**
          const file = command.get("file");
          const queryText = command.get("query") || "";

          if (!file) {
            console.warn("🚨 No file found in command.");
          }
          if (!queryText) {
            console.warn("⚠️ No query text provided.");
          }
        
              response = await axios.post(
                `/${apiMapping[commandType]}`,
                formData,
                { headers: { "Content-Type": "multipart/form-data" } }
              );

        const {
          summary,
          favor_score,
          favor_reasoning_and_observation,
          party_one_name,
          party_two_name,
          party_one_questions,
          party_two_questions,
          party_one_recommendations,
          party_two_recommendations,
        } = response.data;

        setTextData({
          summary,
          favor_score,
          favor_reasoning_and_observation,
          party_one_name,
          party_two_name,
          party_one_questions,
          party_two_questions,
          party_one_recommendations,
          party_two_recommendations,
        });
  
          const chatPayload = {
            query: command.get("query") ,
            context: {
              draft: textData?.draft || "",
              summary: textData?.summary || "",
              favorabilityScore: textData?.favor_score || null,
              favorReasoning: textData?.favor_reasoning_and_observation || "",
              partyOne: {
                name: textData?.party_one_name || "",
                questions: textData?.party_one_questions || [],
                recommendations: textData?.party_one_recommendations || [],
              },
              partyTwo: {
                name: textData?.party_two_name || "",
                questions: textData?.party_two_questions || [],
                recommendations: textData?.party_two_recommendations || [],
              },
            },
          };
          const chat_response = await axios.post("/agent-chat", chatPayload);
          
          
          const { reply: chatResponse } = chat_response.data;
          updateChat(chatResponse);
          console.log("Updated textData for ActionPanel:", textData);
          return {chatResponse };

      } 
      //////////////////////////////// // CASE 2 DRAFT: 🔥 
      if (commandType === "draft") {
      setIsActionPanelOpen(true);
      setActiveSubTab("Output");

      let response;
      let chatResponse;
      let text, draft;
      
      const query = command?.query || "";
      const files = command?.files;
      
      const formData = new FormData();
      formData.append("query", query);  // ✅ Always include the query as FormData
      formData.append("project_id", projectId); // ✅ Send project ID
      if (files && files.length > 0) {
        files.forEach((file) => {
          formData.append("files", file); // ✅ Append each file individually
        });
      }
      if (command?.formType) {
        formData.append("form_type", command.formType); // ✅ Include selected form template if exists
    }
      
      // ✅ Ensure `multipart/form-data` is used for all cases
      response = await axios.post(
          `/${apiMapping[commandType]}`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
      );
      
        // ✅ Extract response data safely
        ({ text, draft } = response?.data || {});

        const isDocxDraft = draft?.type === "docx";

        const newDraft = isDocxDraft
        ? {
            id: draft.id,
            timestamp: new Date().toISOString(),
            type: "docx",
            path: draft.path,
            filename: draft.filename,
          }
        : {
            id: `draft-${Date.now()}`, // ✅ generate frontend ID
            timestamp: new Date().toISOString(),
            type: "text",
            text: draft,
          };

        
        // ✅ Save new draft and persist it correctly
        updateDrafts([newDraft]);

        // ✅ Set the active draft (after allowing state update)
        setTimeout(() => {
          setActiveDraft(newDraft.id);
        }, 50);

      // ✅ Prepare chat context
      const chatPayload = {
          query,
          mode: "draft",
          context: {
              draft: textData?.draft || "",
              summary: textData?.summary || "",
              favorabilityScore: textData?.favor_score || null,
              favorReasoning: textData?.favor_reasoning_and_observation || "",
              partyOne: {
                  name: textData?.party_one_name || "",
                  questions: textData?.party_one_questions || [],
                  recommendations: textData?.party_one_recommendations || [],
              },
              partyTwo: {
                  name: textData?.party_two_name || "",
                  questions: textData?.party_two_questions || [],
                  recommendations: textData?.party_two_recommendations || [],
              },
          },
      };
  
      // ✅ Send chat message to generate assistant response
      const chat_response = await axios.post("/agent-chat", chatPayload);
      ({ reply: chatResponse } = chat_response.data);
  
      // ✅ Update state
      setTextData({ text, draft });
      console.log("Updated textData for ActionPanel:", textData);

  
      setActiveSubTab("Output");

      updateChat(chatResponse);
  
      return { chatResponse };

      } 
      //////////////////////////////// CASE 3 review : 🔥 
      if (commandType === "review") {
        setIsActionPanelOpen(true);
        setActiveSubTab("Output");
      
        const query = command?.query || "";
        const files = command?.files;
        const text = command?.text;
      
        const formData = new FormData();
        formData.append("query", query);
        formData.append("project_id", projectId);
        if (files && files.length > 0) {
          files.forEach((file) => {
            formData.append("files", file); // ✅ Append each file one by one
          });
        } else {
          formData.append("draft_text", text || "");
        }
        
        console.log("🧾 FormData being sent to /agent-review:");
        for (const [key, value] of formData.entries()) {
          console.log(`${key}:`, value);
        }

        const uploadedDraft = files && files.length > 0
  ? {
      id: `uploaded-${Date.now()}`,
      text: text || "",
      timestamp: new Date().toISOString(),
      isUploaded: true,
    }
  : null;

      


        
      
        // ✅ Send to FastAPI's /agent-review endpoint
        const response = await axios.post(
          `/agent-review`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );

        
      
        const { sentences,original, suggested, diff, suggestion_only } = response?.data || {};

        console.log("here is the review suggested: ",suggested)
        console.log("here is the review diff: ",diff)
        console.log("✅ Suggestions Only: ", suggestion_only);

      
        // ✅ Create a New Draft Object (same format as in 'draft')
        const newDraft = {
          id: `review-${Date.now()}`,
          text: diff,               // Used for editor display (with colors)
          cleanText: suggested,     // Optional: you can use this for saving/exporting
          original: original,
          sentences: sentences,     // Full sentence-level metadata
          suggestionOnly: suggestion_only, // 🔥 Add this line
          timestamp: new Date().toISOString(),
        };
        
      
        const allNewDrafts = [];

        // ✅ Add uploaded draft if exists
        if (uploadedDraft) allNewDrafts.push(uploadedDraft);
        
        // ✅ Add reviewed draft
        allNewDrafts.push(newDraft);
        
        // ✅ Update local state and backend (merged inside)
        updateDrafts(allNewDrafts);
        
        // ✅ Set active draft (after short delay)
        setTimeout(() => {
          setActiveDraft(newDraft.id);
        }, 50);
        


      
        // ✅ Prepare chat context — match the structure from 'draft'
        const chatPayload = {
          query,
          mode : "review",
          context: {
            draft: diff,
            summary: "", // Optional: populate later if needed
            favorabilityScore: null,
            favorReasoning: "",
            partyOne: {
              name: "",
              questions: [],
              recommendations: [],
            },
            partyTwo: {
              name: "",
              questions: [],
              recommendations: [],
            },
            diff,
            original,
          },
        };

        // ✅ Get assistant response
        const chat_response = await axios.post("/agent-chat", chatPayload);
        const { reply: chatResponse } = chat_response.data;
      
        // ✅ Update panel state (same style as draft)
        setTextData({ text: original, draft: diff });
        setActiveSubTab("Output");
        updateChat(chatResponse);
        
      
        return { chatResponse };
      }
      

      //////////////////////////////// // CASE 4&5 law and case search : 🔥 
      if (commandType === "law_search" || commandType === "case_search") {
        setIsActionPanelOpen(true);
        setActiveSubTab("References");
      
        const queryText = (typeof command === "string" ? command : command?.query || "").trim();
        const reasoningText = command?.reasoning || ""; // ✅ Extract reasoning from command

        const chatPayload = {
           query: queryText, 
           context: {}, 
           search_type: commandType === "law_search" ? "law" : "case",
           reasoning: reasoningText  // ✅ Add reasoning to the payload

          };
        const response = await axios.post("/agent-query", chatPayload);


        // Extract fields from the API response
        const { answer: chatResponse, reference_document:internalDocuments = []} = response.data;
        setActiveSubTab("References")
        // Update the chat in CommandPanel with the answer
        updateChat(chatResponse);
        console.log("📤 Sending Internal Documents:", JSON.stringify(internalDocuments, null, 2));


        // Accumulate internal documents instead of resetting
        setInternalDocuments((prevDocuments) => {
          const uniqueDocuments = new Map();
      
          // Add previous documents
          prevDocuments.forEach(doc => uniqueDocuments.set(doc.title, doc));
      
          // Add new documents (if they have a new title)
          internalDocuments.forEach(doc => uniqueDocuments.set(doc.title, doc));
      
          const updatedDocuments = Array.from(uniqueDocuments.values());
      
          updateInternalDocuments(updatedDocuments); // ✅ Save immediately
      
          return updatedDocuments;
      });
      
        

        // Update the content of the tab with document details
        setTabs((prevTabs) =>
          prevTabs.map((tab) =>
            tab.id === tabId
              ? {
                  ...tab,
                  content: `Documents Used:\n\nInternal Documents: ${internalDocuments
                    .map((doc, index) => `${index + 1}. ${doc.title}`)
                    .join("\n")}`,
                }
              : tab
          )
        );

        

      return { chatResponse, internalDocuments };
      }

      {
        //////////////////////////////// // CASE 6 SIMPLE CHAT: 🔥 
        const queryText = typeof command === "string" ? command.trim() : command?.query?.trim() || "";
        const chatPayload = { 
          query: queryText, 
          context: {
            draft: textData?.draft || "",
            summary: textData?.summary || "",
            favorabilityScore: textData?.favor_score || null,
            favorReasoning: textData?.favor_reasoning_and_observation || "",
            partyOne: {
              name: textData?.party_one_name || "",
              questions: textData?.party_one_questions || [],
              recommendations: textData?.party_one_recommendations || [],
            },
            partyTwo: {
              name: textData?.party_two_name || "",
              questions: textData?.party_two_questions || [],
              recommendations: textData?.party_two_recommendations || [],
            },
          },
        };
  
        const chat_response = await axios.post("/agent-chat", chatPayload, {
          params: { project_id: projectId }, // ✅ Add project_id as a query parameter
        });
        const { reply: chatResponse } = chat_response.data;

        updateChat(chatResponse);
        console.log("Updated textData for ActionPanel:", textData);
        return {chatResponse };
      }

    } catch (error) {
      console.error("Error processing command:", error.message);
    } finally {
      setLoading(false);
    }
  };
  
  // const handleFileUpload = (file) => {
  //   if (!file) {
  //     console.error("No file provided to handleFileUpload.");
  //     return;
  //   }
  //   setUploadedFile(file); // Store the uploaded file
  //   console.log("Uploaded file:", file); // Debugging log
  // };

  const handleFileUpload = (incomingFiles) => {
    if (!incomingFiles || incomingFiles.length === 0) {
      console.error("No files provided to handleFileUpload.");
      return;
    }
  
    const filesArray = Array.isArray(incomingFiles) ? incomingFiles : [incomingFiles];
  
    setUploadedFiles((prev) => [...prev, ...filesArray]);
  };
    

  return (
<motion.div
  initial={{ opacity: 0, filter: "blur(5px)" }}
  animate={isFadingOut ? { opacity: 0.5, filter: "blur(5px)" } : { opacity: 1, filter: "blur(0px)" }}
  exit={{ opacity: 0, filter: "blur(5px)" }}
  transition={{ duration: 0.8 }}
>

    <div className="app-container flex h-screen">
      {/* Sidebar */}
      <div className={`relative ${isSidebarOpen ? "w-48" : "w-16"} transition-all duration-300 ease-in-out delay-150`}>
        <Sidebar 
        isOpen={isSidebarOpen}
        projectId={projectId}  />
        <button
          onClick={toggleSidebar}
          className="absolute top-6 right-4 p-2 text-black rounded-md"
        >
          {isSidebarOpen ? <ChevronLeft size={18} /> : <ChevronRight size={18} />}
        </button>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex">
      <CommandPanel
          onCommand={handleCommandFromPanel}
          onFileUpload={handleFileUpload}
          onClearFile={handleClearUploadedFile} // Pass the clear function
          uploadedFiles={uploadedFiles} // Pass the uploaded file to the command panel
          activeTabId={activeTabId}
          updateChat={updateChat} // Pass the function to handle chat updates
          className="w-full"
          projectId={projectId} // ✅ Pass projectId
          setProjectId={setProjectId} // ✅ Allow updates from CommandPanel
          messages={messages} // ✅ Pass messages to CommandPanel
          setMessages={setMessages} // ✅ Allow CommandPanel to update messages
          activeDraft={activeDraft}
          drafts={drafts} // ✅ Pass drafts into CommandPanel
          selectedMode={selectedMode}            // ✅ pass current mode
          setSelectedMode={setSelectedMode}      // ✅ allow updating mode
        />
        {/* 🔥 Only Show ActionPanel When There Is Chat Content */}
        <div
          className={`transition-all duration-300 ease-in-out delay-150 overflow-hidden ${
            isActionPanelOpen ? "max-w-[600px] w-full opacity-100" : "w-0"
          }`}
        >
        <button
            onClick={toggleActionPanel}
            className="absolute top-4 right-4 p-2 bg-white text-black rounded-md"
          >
          {isActionPanelOpen ? <ChevronRight size={18} /> : <ChevronLeft size={18} />}
        </button>

        {isActionPanelOpen && (
        <ActionPanel
          projectId={projectId} // ✅ Pass projectId to track documents per project
          tabs={tabs}
          activeTabId={activeTabId}
          onAddTab={handleAddTab}
          onCloseTab={handleCloseTab}
          onSwitchTab={setActiveTabId}
          drafts={drafts} // ✅ instead of textData?.drafts
          internalDocuments={internalDocuments}
          setInternalDocuments={setInternalDocuments}
          extractedData = {extractedData}
          onUpdateDraft={(updatedDraft) =>
            setTextData((prev) => ({
              ...prev,
              draft: updatedDraft
            }))
          }
          activeDraft={activeDraft}
          setActiveDraft={setActiveDraft}
          className="flex-1"
          activeSubTab={activeSubTab} // Pass activeSubTab
          setActiveSubTab={setActiveSubTab} // Pass setActiveSubTab
          loading={loading}
          setIsActionPanelOpen={setIsActionPanelOpen} // ✅ Pass function to close it
          messages={messages} // ✅ Pass messages to ActionPanel (if needed)
          selectedMode={selectedMode} 
        />
      )}

        </div>
      </div>
    </div>
   </motion.div>
  );
};

export default AppLayout;
