import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "./axiosConfig";
import Sidebar from "./components/Sidebar"; // Import Sidebar component
import { ChevronLeft, ChevronRight } from "lucide-react";

export default function AccountPage() {
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate(); // For navigation
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Sidebar state
  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);



  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          throw new Error("Authentication token not found.");
        }

        const response = await axios.get("/api/account", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserInfo(response.data);
      } catch (err) {
        console.error("Error fetching user information:", err);
        setError(err.response?.data?.detail || "Failed to fetch user information.");
      }
    };
    fetchUserInfo();
  }, []);

  if (error) {
    return <div className="p-6 bg-red-100 text-red-800 rounded-lg">{error}</div>;
  }

    // Navigation handler
    const handleBack = () => {
        navigate("/");
      };

    return (
    <div className="flex h-screen bg-gray-100">
        {/* Sidebar Section */}
        <div className={`relative ${isSidebarOpen ? "w-48" : "w-16"} transition-all duration-300 ease-in-out`}>
        <Sidebar isOpen={isSidebarOpen} />
        <button
            onClick={toggleSidebar}
            className="absolute top-6 right-4 p-2 text-black rounded-md"
        >
            {isSidebarOpen ? <ChevronLeft size={18} /> : <ChevronRight size={18} />}
        </button>
        </div>
    
        {/* Main Content */}
        <div className="flex flex-1 justify-center items-center px-6">
        <div className="bg-white shadow-lg rounded-2xl p-6 max-w-2xl w-full">
            <h1 className="text-2xl font-bold text-gray-800 mb-6">ข้อมูลบัญชี</h1>
    
            {/* Show a placeholder until user data is loaded */}
            {userInfo ? (
            <div className="space-y-4">
                <div className="flex justify-between">
                <span className="font-medium text-gray-600">ชื่อผู้ใช้ (Username):</span>
                <span className="text-gray-800">{userInfo.username}</span>
                </div>
                <div className="flex justify-between">
                <span className="font-medium text-gray-600">อีเมล (Email):</span>
                <span className="text-gray-800">{userInfo.email}</span>
                </div>
                <div className="flex justify-between">
                <span className="font-medium text-gray-600">การสมัครสมาชิก (Subscription):</span>
                <span className="text-gray-800">
                    {userInfo.subscription_commitment ? userInfo.subscription_commitment : "free"}
                </span>
                </div>
                <div className="flex justify-between">
                <span className="font-medium text-gray-600">สถานะแผนบริการ (Plan Status):</span>
                <span className={`text-gray-800 ${userInfo.status === "active" ? "text-green-600" : "text-red-600"}`}>
                    {userInfo.status === "active" ? userInfo.status : "Trial"}
                </span>
                </div>
                <div className="flex justify-between">
                <span className="font-medium text-gray-600">วันที่สมัครใช้งาน (Signup Date):</span>
                <span className="text-gray-800">{new Date(userInfo.signup_datetime).toLocaleDateString()}</span>
                </div>
            </div>
            ) : (
            <div className="text-gray-500">กำลังโหลดข้อมูลบัญชี...</div> // 👈 Improved loading text
            )}
    
            <div className="mt-6">
            <button
                className="w-full mt-2 px-4 border border-black py-2 bg-white text-black rounded-md hover:bg-slate-900 hover:text-white mb-4"
                onClick={handleBack}
            >
                กลับสู่การใช้งาน
            </button>
            </div>
        </div>
        </div>
    </div>
    );
      
}
