import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar"; // ✅ Import Sidebar
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { FileText, BookOpen, MessageSquare } from "lucide-react";

import axios from "../axiosConfig";

const VaultPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get("/api/projects", {
          headers: { Authorization: `Bearer ${authToken}` },
        });

        // 🔥 Sort projects by lastEdited (Most Recent First)
        const sortedProjects = response.data.sort(
          (a, b) => new Date(b.lastEdited) - new Date(a.lastEdited)
        );

        setProjects(sortedProjects);
      } catch (error) {
        console.error("🚨 Error fetching projects:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleProjectClick = (projectId) => {
    navigate(`/project/${projectId}`);
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar Section */}
      <div className={`relative ${isSidebarOpen ? "w-48" : "w-16"} transition-all duration-300 ease-in-out`}>
        <Sidebar isOpen={isSidebarOpen} />
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="absolute top-6 right-4 p-2 text-black rounded-md"
        >
          {isSidebarOpen ? <ChevronLeft size={18} /> : <ChevronRight size={18} />}
        </button>
      </div>

      {/* Main Content - Fixed Size & Scrollable */}
      <div className="flex flex-1 flex-col items-center px-6 py-8 overflow-hidden">

        {/* ✅ Scrollable Project List */}
        <div className="w-full max-w-8xl h-[100vh] overflow-y-auto bg-white shadow-lg rounded-lg p-4 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
          {loading ? (
            <p className="text-gray-500 text-center">กำลังโหลด Vault ...</p>
          ) : projects.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {projects.map((project) => (
              <div
                key={project.id}
                className="bg-white shadow-md rounded-lg p-4 border hover:bg-gray-200 cursor-pointer transition-all"
                onClick={() => handleProjectClick(project.id)}
              >
                <h2 className="text-lg font-semibold text-sm text-gray-800">{project.title}</h2>
                <p className="text-xs text-gray-600 mt-1">
                  แก้ไขล่าสุด: {new Date(project.lastEdited).toLocaleDateString()}
                </p>

                <div className="mt-3 flex flex-wrap gap-2 text-xs text-gray-700">
                  <div className="flex items-center gap-1 bg-gray-100 px-2 py-1 rounded">
                    <FileText size={14} className="text-gray-600" />
                    <span>ร่าง {project.draftCount ?? 0}</span>
                  </div>
                  <div className="flex items-center gap-1 bg-gray-100 px-2 py-1 rounded">
                    <BookOpen size={14} className="text-gray-600" />
                    <span>เอกสาร {project.internalDocumentCount ?? 0}</span>
                  </div>
                  <div className="flex items-center gap-1 bg-gray-100 px-2 py-1 rounded">
                    <MessageSquare size={14} className="text-gray-600" />
                    <span>แชท {project.messageCount ?? 0}</span>
                  </div>
                </div>
              </div>
            ))}
            </div>
          ) : (
            <p className="text-gray-500 text-center">ยังไม่มีโปรเจคที่สร้างขึ้น</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default VaultPage;
