import React, { useState, useEffect } from "react";
import { Home, FileText, PlusCircle, Folder, ChevronDown, ChevronUp } from "lucide-react";
import { useNavigate } from "react-router-dom";
import axios from "../axiosConfig"; // ✅ Adjust path based on your project structure

const Sidebar = ({ isOpen,projectId }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [recentProjects, setRecentProjects] = useState([]);
  const [showProjects, setShowProjects] = useState(true);
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get("/api/projects", {
          headers: { Authorization: `Bearer ${authToken}` },
        });
  
        // ✅ Sort projects by last edited or created date
        const sortedProjects = response.data.sort((a, b) => 
          new Date(b.lastEdited || b.createdAt) - new Date(a.lastEdited || a.createdAt)
        );
  
        setRecentProjects(sortedProjects);
      } catch (error) {
        console.error("🚨 Error fetching recent projects:", error);
      }
    };
  
    fetchProjects();
  }, [projectId]); // ✅ Re-fetch when `projectId` changes
  
  

  const handleNewProjectClick = () => {
    // navigate(`/`);
    window.location.href = "/"; // ✅ Hard refresh resets entire state

  };

  const toggleSettingsMenu = () => {
    setIsSettingsOpen((prev) => !prev);
  };

  const handleManageAccount = () => {
    setIsSettingsOpen(false);
    navigate('/account');
  };

  const handleLogout = () => {
    console.log("Logging out...");
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    window.location.href = "/auth";
  };

  return (
    <div
      className={`h-full bg-white flex flex-col justify-between ${
        isOpen ? "p-4 w-48" : "p-2 w-16"
      } transition-all duration-300 relative`}
    >
      {/* Top Section */}
      <div>
        <div className="my-2 h-10 flex">
          {isOpen && <h2 className="text-2xl font-bold text-gray-800">KHORN AI</h2>}
        </div>

        {/* Navigation Items */}
        <ul className="space-y-4 mt-2">
          <li>
            <button
              className={`flex items-center text-sm gap-2 w-full p-2 bg-white rounded-md hover:bg-gray-200 transition-all ${
                !isOpen && "justify-center"
              }`}
              onClick={() => navigate("/vault")}
            >
              <Home size={15} />
              {isOpen && <span>Vault</span>}
            </button>
          </li>
          <li>
            <label
              className={`flex items-center text-sm gap-2 w-full p-2 bg-white rounded-md hover:bg-gray-200 cursor-pointer transition-all ${
                !isOpen && "justify-center"
              }`}
              onClick={handleNewProjectClick}
            >
              <PlusCircle size={15} />
              {isOpen && <span>New Project</span>}
            </label>
          </li>
        </ul>

        {/* 🔥 Recent Projects Section */}
        {isOpen && (
          <div className="mt-4">
            <div
              className="flex items-center justify-between cursor-pointer hover:bg-gray-100 p-2 rounded-md transition-all"
              onClick={() => setShowProjects(!showProjects)}
            >
              <span className="flex items-center text-sm gap-2">
                <Folder size={15} />
                <span>Recent Projects</span>
              </span>
              {showProjects ? <ChevronUp size={15} /> : <ChevronDown size={15} />}
            </div>

            {showProjects && (
              <div className="mt-2 max-h-[450px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
                {recentProjects.length > 0 ? (
                  recentProjects.map((project) => (
                    <button
                      key={project.id}
                      className="w-full text-left px-2 my-2 py-1 block text-sm text-gray-800 hover:bg-gray-200 rounded transition-all"
                      onClick={() => navigate(`/project/${project.id}`)}
                    >
                      {project.title.replace(/^["']|["']$/g, "").trim()}
                    </button>
                  ))
                ) : (
                  <p className="text-gray-500 text-sm text-center">No projects yet</p>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {/* Bottom Section - Account & Logout */}
      <div className="my-2 relative flex items-center justify-start pl-2 cursor-pointer">
        <button
          onClick={toggleSettingsMenu}
          className="bg-gray-300 text-black p-3 rounded-full shadow text-lg font-bold flex items-center justify-center"
          style={{ width: "30px", height: "30px" }}
        >
          {localStorage.getItem("userId")?.charAt(0).toUpperCase() || "U"}
        </button>
        {isOpen && (
          <span
            onClick={toggleSettingsMenu}
            className="text-sm ml-3 my-2 text-gray-700 font-medium cursor-pointer hover:text-black"
          >
            Account
          </span>
        )}
        {isSettingsOpen && (
          <div className="absolute bottom-1 right-[-170px] rounded-xl translate-x-4 w-48 bg-white border border-gray-200 rounded shadow-lg z-50">
            <button onClick={handleManageAccount} className="w-full px-4 py-2 text-left hover:bg-gray-100">
              Account (My Plan)
            </button>
            <hr className="my-1 border-gray-200" />
            <button onClick={handleLogout} className="w-full px-4 py-2 text-left hover:bg-gray-100">
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
