import React from "react";
import { RotateCcw, Copy, Download, Edit, Eye } from "lucide-react"; // Added icons for Edit and Preview
import { Document, Packer, Paragraph, TextRun } from "docx";


const ActionButtons = ({ draftContent,draftType,projectId }) => {
  const handleCopy = () => {
    if (!draftContent) {
      alert("No draft content available to copy!");
      return;
    }

    // Format the content for HTML
    const formattedContent = draftContent
      .replace(/^### (.*?)$/gm, "<h3>$1</h3>")
      .replace(/^## (.*?)$/gm, "<h2>$1</h2>")
      .replace(/^# (.*?)$/gm, "<h1>$1</h1>")
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
      .replace(/\*(.*?)\*/g, "<em>$1</em>")
      .replace(/\n{2,}/g, "<br /><br />")
      .replace(/\n/g, "<br />");



    // Create a temporary element to hold the content
    const tempElement = document.createElement("div");
    tempElement.innerHTML = formattedContent;
    document.body.appendChild(tempElement);

    // Copy the content
    const range = document.createRange();
    range.selectNodeContents(tempElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand("copy");
      alert("Draft copied to clipboard!");
    } catch (error) {
      console.error("Copy to clipboard failed:", error);
      alert("Failed to copy the draft to clipboard.");
    }

    // Remove the temporary element
    document.body.removeChild(tempElement);
  };

  // const handleDownload = async () => {
  //   if (!draftContent) {
  //     alert("No draft content available to download!");
  //     return;
  //   }

  //   try {
  //     // Convert draft content into `.docx` paragraphs
  //     const parseDraftToDocx = (draft) => {
  //       const lines = draft.split("\n");
  //       return lines.map((line) => {
  //         const children = [];
  //         if (line.startsWith("### ")) {
  //           children.push(
  //             new TextRun({
  //               text: line.replace("### ", "").trim(),
  //               bold: true,
  //               size: 28,
  //             })
  //           );
  //         } else if (line.startsWith("## ")) {
  //           children.push(
  //             new TextRun({
  //               text: line.replace("## ", "").trim(),
  //               bold: true,
  //               size: 36,
  //             })
  //           );
  //         } else if (line.startsWith("# ")) {
  //           children.push(
  //             new TextRun({
  //               text: line.replace("# ", "").trim(),
  //               bold: true,
  //               size: 48,
  //             })
  //           );
  //         } else {
  //           // Process inline styles for bold and italic text
  //           const segments = line.split(/(\*\*.*?\*\*|\*.*?\*)/);
  //           segments.forEach((segment) => {
  //             if (segment.startsWith("**")) {
  //               children.push(
  //                 new TextRun({
  //                   text: segment.replace(/\*\*/g, ""),
  //                   bold: true,
  //                 })
  //               );
  //             } else if (segment.startsWith("*")) {
  //               children.push(
  //                 new TextRun({
  //                   text: segment.replace(/\*/g, ""),
  //                   italics: true,
  //                 })
  //               );
  //             } else {
  //               children.push(new TextRun({ text: segment }));
  //             }
  //           });
  //         }
  //         return new Paragraph({ children });
  //       });
  //     };

  //     const paragraphs = parseDraftToDocx(draftContent);

  //     // Create a `.docx` document
  //     const doc = new Document({
  //       sections: [
  //         {
  //           children: paragraphs,
  //         },
  //       ],
  //     });

  //     // Generate the `.docx` file
  //     const blob = await Packer.toBlob(doc);
  //       // Get the current date in DDMMYYYY format
  //     const now = new Date();
  //     const formattedDate = `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}`;

  //     // Create the file name with the date
  //     const fileName = `${formattedDate}_เอกสาร่าง.docx`;

  //     // Create a link to download the file
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(blob);
  //     link.download = fileName; // Use the formatted date in the file name
  //     link.click();
  //   } catch (error) {
  //     console.error("Error generating .docx file:", error);
  //     alert("Failed to generate the document.");
  //   }
  // };
  const extractDraftId = (path) => {
    const matches = path.match(/draft-\d+/);
    return matches ? matches[0] : "";
  };

  const handleDownload = async () => {
    if (!draftContent) {
      alert("No draft content available to download!");
      return;
    }
  
    try {
      const now = new Date();
      const formattedDate = `${now.getFullYear()}${String(
        now.getMonth() + 1
      ).padStart(2, "0")}${String(now.getDate()).padStart(2, "0")}`;
  
      if (draftType === "form_fill") {
        const draftId = extractDraftId(draftContent);
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/draft-download/${draftId}`);
      
        if (!response.ok) throw new Error("Failed to fetch document.");
      
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${formattedDate}_เอกสารฟอร์ม.docx`;
        link.click();
        return;
      }
      
  
      // ✅ Fallback for freeform markdown-style content
      const { Document, Packer, Paragraph, TextRun } = await import("docx");
  
      const parseDraftToDocx = (draft) => {
        const lines = draft.split("\n");
        return lines.map((line) => {
          const children = [];
  
          if (line.startsWith("### ")) {
            children.push(
              new TextRun({ text: line.replace("### ", "").trim(), bold: true, size: 28 })
            );
          } else if (line.startsWith("## ")) {
            children.push(
              new TextRun({ text: line.replace("## ", "").trim(), bold: true, size: 36 })
            );
          } else if (line.startsWith("# ")) {
            children.push(
              new TextRun({ text: line.replace("# ", "").trim(), bold: true, size: 48 })
            );
          } else {
            const segments = line.split(/(\*\*.*?\*\*|\*.*?\*)/);
            segments.forEach((segment) => {
              if (segment.startsWith("**")) {
                children.push(
                  new TextRun({ text: segment.replace(/\*\*/g, ""), bold: true })
                );
              } else if (segment.startsWith("*")) {
                children.push(
                  new TextRun({ text: segment.replace(/\*/g, ""), italics: true })
                );
              } else {
                children.push(new TextRun({ text: segment }));
              }
            });
          }
  
          return new Paragraph({ children });
        });
      };
  
      const paragraphs = parseDraftToDocx(draftContent);
      const doc = new Document({ sections: [{ children: paragraphs }] });
      const blob = await Packer.toBlob(doc);
  
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${formattedDate}_เอกสาร่าง.docx`;
      link.click();
    } catch (error) {
      console.error("❌ Error downloading the file:", error);
      alert("ไม่สามารถดาวน์โหลดไฟล์ได้");
    }
  };
  
  return (
    <div className="flex space-x-2 text-sm">
      {/* <button
        onClick={() => onToggleMode(!isEditing)}
        className="flex items-center gap-2 px-4 py-2 bg-white border rounded-md shadow-sm hover:bg-gray-100"
      >
        {isEditing ? <Eye size={18} /> : <Edit size={18} />}
      </button> */}

      <button
          onClick={() => alert("กำลังมาในเร็วๆนี้ๆ")} // Replace with your actual redo logic
          className="flex items-center gap-2 px-4 py-2 bg-white border rounded-md shadow-sm hover:bg-gray-100"
        >
          <RotateCcw size={18} /> ย้อนกลับ
      </button>
      <button
        onClick={handleCopy}
        className="flex items-center gap-2 px-4 py-2 bg-white border rounded-md shadow-sm hover:bg-gray-100"
      >
        <Copy size={18} /> คัดลอก
        {/* Copy */}
      </button>
      <button
        onClick={handleDownload}
        className="flex items-center gap-2 px-4 py-2 bg-white border rounded-md shadow-sm hover:bg-gray-100"
      >
        <Download size={18} /> ดาวน์โหลด
        {/* Download */}
      </button>
    </div>
  );
};

export default ActionButtons;
