import React, { useState } from "react";
import { ChevronDown, ChevronUp, Filter } from "lucide-react";

const getMatchLevelStyle = (level) => {
  switch (level?.toLowerCase()) {
    case "direct":
      return "bg-green-100 text-green-800";
    case "partial":
      return "bg-yellow-100 text-yellow-800";
    case "none":
      return "bg-red-100 text-red-800";
    default:
      return "bg-gray-100 text-gray-800";
  }
};

const translateTermType = (type) => {
  const map = {
    legal_action: "การกระทำทางกฎหมาย",
    obligation: "หน้าที่",
    party: "คู่ความ",
    legal_consequence: "ผลทางกฎหมาย"
  };
  return map[type] || type;
};

const getMatchLevelBadge = (level) => {
  switch (level?.toLowerCase()) {
    case "direct":
      return { label: "ตรงกับคำถาม", color: "bg-green-100 text-green-800" };
    case "partial":
      return { label: "เกี่ยวข้องบางส่วน", color: "bg-yellow-100 text-yellow-800" };
    case "none":
      return { label: "ไม่เกี่ยวข้อง", color: "bg-red-100 text-red-800" };
    default:
      return { label: level || "-", color: "bg-gray-100 text-gray-800" };
  }
};

const LegalAnalysisTable = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sortAsc, setSortAsc] = useState(true);
  const [matchFilter, setMatchFilter] = useState(null);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);

  if (!data || data.length === 0) return null;

  const directCount = data.filter(row => row.match_level?.toLowerCase() === "direct").length;
  const partialCount = data.filter(row => row.match_level?.toLowerCase() === "partial").length;
  const exampleTerms = data.find(row => row.match_level?.toLowerCase() === "direct")?.terms?.slice(0, 3) || [];

  let filteredData = [...data];
  if (matchFilter) {
    filteredData = filteredData.filter(row => row.match_level?.toLowerCase() === matchFilter);
  }
  filteredData.sort((a, b) => {
    // Define priority: direct < partial < none
    const levelOrder = { direct: 0, partial: 1, none: 2 };
  
    const aLevel = levelOrder[a.match_level?.toLowerCase()] ?? 99;
    const bLevel = levelOrder[b.match_level?.toLowerCase()] ?? 99;
  
    // ✅ Sort first by match_level priority
    if (aLevel !== bLevel) {
      return aLevel - bLevel;
    }
  
    // ✅ Then by section number based on current sortAsc
    const aVal = parseInt(a.title);
    const bVal = parseInt(b.title);
    return sortAsc ? aVal - bVal : bVal - aVal;
  });
  

  return (
    <div className="mt-6 w-full">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between px-4 py-3 bg-gray-100 hover:bg-gray-200 cursor-pointer rounded-lg border border-gray-300 shadow-sm"
      >
        <div className="text-sm text-gray-800">
          <div className="font-medium">ตารางวิเคราะห์ข้อกฎหมาย</div>
          {!isOpen && (
            <div className="text-xs text-gray-500 mt-1">
              ทั้งหมด {data.length} รายการ • ตรง {directCount} • บางส่วน {partialCount} • ตัวอย่างคำ: {exampleTerms.join(", ")}
            </div>
          )}
        </div>
        {isOpen ? (
          <ChevronUp size={18} className="text-gray-600" />
        ) : (
          <ChevronDown size={18} className="text-gray-600" />
        )}
      </div>

      <div className={`transition-all duration-500 ease-in-out overflow-hidden ${isOpen ? "max-h-[1000px] opacity-100 mt-3" : "max-h-0 opacity-0"}`}>
        <div className="mt-3 max-w-[650px] overflow-x-auto rounded-xl border border-gray-200 shadow scroll-smooth">
          <table className="table-auto min-w-[900px] w-full text-sm bg-white border-collapse">
            <thead className="bg-gray-100 text-gray-700 text-left">
              <tr>
                <th className="px-4 py-3 font-medium border-b border-gray-300 whitespace-nowrap">
                  <div className="flex items-center gap-1">
                    เอกสารกฎหมายที่เกี่ยวข้อง
                    <button onClick={() => setSortAsc(!sortAsc)}>{sortAsc ? <ChevronUp size={14} /> : <ChevronDown size={14} />}</button>
                  </div>
                </th>
                <th className="px-4 py-3 font-medium border-b border-gray-300 whitespace-nowrap">รายละเอียด</th>
                <th className="px-4 py-3 font-medium border-b border-gray-300 whitespace-nowrap relative">
                  <div className="flex items-center gap-1">
                    ระดับความเกี่ยวข้อง
                    <button onClick={() => setShowFilterDropdown(!showFilterDropdown)}><Filter size={14} /></button>
                  </div>
                  {showFilterDropdown && (
                    <div className="absolute top-full mt-1 bg-white border rounded shadow z-10">
                      <ul className="text-xs">
                        <li><button className="block px-4 py-2 hover:bg-gray-100 w-full text-left" onClick={() => setMatchFilter(null)}>ทั้งหมด</button></li>
                        <li><button className="block px-4 py-2 hover:bg-gray-100 w-full text-left" onClick={() => setMatchFilter("direct")}>ตรงกับคำถาม</button></li>
                        <li><button className="block px-4 py-2 hover:bg-gray-100 w-full text-left" onClick={() => setMatchFilter("partial")}>เกี่ยวข้องบางส่วน</button></li>
                        <li><button className="block px-4 py-2 hover:bg-gray-100 w-full text-left" onClick={() => setMatchFilter("none")}>ไม่เกี่ยวข้อง</button></li>
                      </ul>
                    </div>
                  )}
                </th>
                <th className="px-4 py-3 font-medium border-b border-gray-300 whitespace-nowrap">คำที่เกี่ยวข้อง</th>
                <th className="px-4 py-3 font-medium border-b border-gray-300 whitespace-nowrap">ประเภทคำ</th>
                <th className="px-4 py-3 font-medium border-b border-gray-300 whitespace-nowrap">หมายเหตุ</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row, rowIndex) => (
                <tr key={rowIndex} className="hover:bg-gray-50">
                  <td className="px-4 py-3 border-t border-gray-100 align-top whitespace-nowrap">{row.title}</td>
                  <td className="px-4 py-3 border-t border-gray-100 align-top whitespace-normal">{row.detail}</td>
                  <td className="px-4 py-3 border-t border-gray-100 align-top whitespace-nowrap">
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${getMatchLevelStyle(row.match_level)}`}>
                      {getMatchLevelBadge(row.match_level).label}
                    </span>
                  </td>
                  <td className="px-4 py-3 border-t border-gray-100 align-top whitespace-normal">
  <div className="flex flex-wrap gap-1">
    {row.terms?.map((item, i) => (
      <span key={i} className="bg-gray-100 text-black text-xs px-2 py-1 rounded-md">
        {item}
      </span>
    ))}
  </div>
</td>

<td className="px-4 py-3 border-t border-gray-100 align-top whitespace-normal">
  <div className="flex flex-wrap gap-1">
    {row.term_types?.map((type, i) => (
      <span key={i} className="bg-gray-100 text-black text-xs px-2 py-1 rounded-md">
        {translateTermType(type)}
      </span>
    ))}
  </div>
</td>

                  <td className="px-4 py-3 border-t border-gray-100 align-top whitespace-normal">{row.notes}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LegalAnalysisTable;
