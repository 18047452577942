import React, { useState, useEffect, useRef } from "react";
import { Check, X, Pencil, RotateCcw } from "lucide-react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import ActionButtons from "./ActionButtons";
import axios from './axiosConfig';
// import Typewriter from "typewriter-effect";

const getDraftContent = (draftObj) => {
    if (!draftObj) return "";
    if (draftObj.type === "docx") return draftObj.preview || "";
    if (typeof draftObj.text === "string") return draftObj.text;
    return "";
  };
  

const DraftOutput = ({ drafts, activeDraft, setActiveDraft, onUpdateDraft, loading,selectedMode,projectId }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [renderedDraft, setRenderedDraft] = useState("");
    const activeDraftObj = drafts.find((d) => d.id === activeDraft);
    // const draftText = activeDraftObj?.text || "";
    const [switchingDraft, setSwitchingDraft] = useState(false);
    const isReviewDraft = Boolean(activeDraftObj?.cleanText); // indicates there's a suggestion
    const [suggestions, setSuggestions] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [allSentences, setAllSentences] = useState([]);
    const [decisionMap, setDecisionMap] = useState({});  // key = id, value = accepted boolean
    const [allSuggestions, setAllSuggestions] = useState([]); // full list for rendering
    

    
    let draftText = "";

    if (activeDraftObj?.type === "docx" && activeDraftObj?.preview) {
    draftText = activeDraftObj.preview; // 🔥 Use HTML preview for .docx
    } else {
    draftText = activeDraftObj?.text || "";
    }

    // When fetching draft with review data:
    useEffect(() => {
        if (isReviewDraft && activeDraftObj?.sentences) {
          setAllSentences(activeDraftObj.sentences || []);
      
          const suggestionObj = activeDraftObj.suggestionOnly;
          console.log("🧠 suggestionOnly:", suggestionObj);
      
          const updated = suggestionObj
            ? Object.entries(suggestionObj).map(([id, val]) => {
                const matched = activeDraftObj.sentences.find((s) => s.id === parseInt(id));
                return {
                    ...matched,
                    suggested: val.suggested,
                    diff: val.diff,
                    accepted: undefined,
                };
                })
            : [];
            setSuggestions(updated); // used in review UI
            setAllSuggestions(updated); // used for rendering

        } else {
          setSuggestions([]);
        }
      }, [activeDraftObj]);

    // ✅ Re-render draft display when text updates (e.g. after Accept All/Decline All)
    // useEffect(() => {
    //     if (isReviewDraft && activeDraftObj?.text) {
    //     setRenderedDraft(legacyFormatMessage(activeDraftObj.text));
    //     }
    // }, [activeDraftObj?.text]);
    useEffect(() => {
        if (isReviewDraft && activeDraftObj) {
            const content = getDraftContent(activeDraftObj);
if (activeDraftObj?.type === "docx") {
  setRenderedDraft(content); // don't format HTML
} else {
  setRenderedDraft(legacyFormatMessage(content));
}
console.log("🖨️ Final renderedDraft", renderedDraft);

        }
      }, [activeDraftObj]);
      
      
      
      

    //   combine the sentence
    const buildAcceptedText = (allSentences, suggestions, decisionMap) => {
        return allSentences
          .map((sentence) => {
            const match = allSuggestions.find((s) => s.id === sentence.id);
            const decision = decisionMap?.[sentence.id];
      
            const content =
              decision === true
                ? match?.suggested
                : decision === false
                ? sentence.original
                : match?.diff ?? sentence.original;
      
            const breakStr =
              sentence.break_type === "\n\n"
                ? "\n\n"
                : sentence.break_type === "\n"
                ? "\n"
                : "";
      
            return content + breakStr;
          })
          .join("");
      };
      
      
      const patchDraft = async (updatedText, updatedCleanText, updatedSentences, updatedSuggestionMap) => {
        if (!projectId || !activeDraft) {
          console.error("🚨 Missing projectId or activeDraft");
          return;
        }
      
        try {
          const response = await axios.patch(`/api/projects/${projectId}/patch_draft`, {
            draft_id: activeDraft,
            updates: {
              text: updatedText,
              cleanText: updatedCleanText,
              sentences: updatedSentences,
              suggestionOnly: updatedSuggestionMap
            }
          });
          console.log("✅ Draft patched:", response.data);
        } catch (err) {
          console.error("❌ Failed to patch draft:", err);
        }
      };
      

      const handleAccept = (index) => {
        setSuggestions((prev) => {
          const current = prev[index];
          const updated = [...prev];
          updated.splice(index, 1); // remove current
      
          // ✅ Add decision to decisionMap
          setDecisionMap((prevMap) => ({
            ...prevMap,
            [current.id]: true,
          }));
      
          const finalText = buildAcceptedText(allSentences, updated, {
            ...decisionMap,
            [current.id]: true,
          });
          onUpdateDraft(finalText);
          setRenderedDraft(legacyFormatMessage(finalText));
      
          const updatedSuggestionMap = {};
          updated.forEach((s) => {
            if (!decisionMap[s.id]) {
              updatedSuggestionMap[s.id] = { suggested: s.suggested, diff: s.diff };
            }
          });
      
          patchDraft(finalText, finalText, allSentences, updatedSuggestionMap);
      
          setCurrentIndex((prevIndex) =>
            Math.min(updated.length - 1, prevIndex)
          );
      
          return updated;
        });
      };
      
      const handleDecline = (index) => {
        setSuggestions((prev) => {
          const current = prev[index];
          const updated = [...prev];
          updated.splice(index, 1);
      
          setDecisionMap((prevMap) => ({
            ...prevMap,
            [current.id]: false,
          }));
      
          const finalText = buildAcceptedText(allSentences, updated, {
            ...decisionMap,
            [current.id]: false,
          });
          onUpdateDraft(finalText);
          setRenderedDraft(legacyFormatMessage(finalText));
      
          const updatedSuggestionMap = {};
          updated.forEach((s) => {
            if (!decisionMap[s.id]) {
              updatedSuggestionMap[s.id] = { suggested: s.suggested, diff: s.diff };
            }
          });
      
          patchDraft(finalText, finalText, allSentences, updatedSuggestionMap);
      
          setCurrentIndex((prevIndex) =>
            Math.min(updated.length - 1, prevIndex)
          );
      
          return updated;
        });
      };
      

    const handleSelectDraft = (draftId) => {
        setSwitchingDraft(true);
        console.log("✅ Draft selected in DraftOutput:", draftId);
      
        const selectedDraft = drafts.find((d) => d.id === draftId);
        if (selectedDraft) {
          setActiveDraft(draftId);
        //   setRenderedDraft(legacyFormatMessage(selectedDraft.text || ""));
        //   const draftContent =
        //     selectedDraft.type === "docx"
        //         ? selectedDraft.preview || ""
        //         : selectedDraft.text || "";

            // setRenderedDraft(legacyFormatMessage(draftContent));
        
            setRenderedDraft(legacyFormatMessage(getDraftContent(selectedDraft)));

                
          if (selectedMode === "review" && selectedDraft.sentences) {
            const filtered = selectedDraft.sentences
              .filter((s) => s.original !== s.suggested)
              .map((s) => ({
                ...s,
                accepted: undefined,
              }));
            setSuggestions(filtered);
          } else {
            setSuggestions([]);
          }
          
      
          // ✅ Reset review state
          setIsEditing(false);
          setCurrentIndex(0);
          setDecisionMap({});
        }
      
        setTimeout(() => {
          setSwitchingDraft(false);
        }, 300);
      };
      

      
    //   useEffect(() => {
    //     if (!drafts || drafts.length === 0) {
    //       setActiveDraft(null);
    //       setRenderedDraft("");
    //       return;
    //     }
      
    //     // ✅ Only set activeDraft on first load
    //     if (!activeDraft) {
    //       const latestDraft = drafts[drafts.length - 1]; // optional: use most recent
    //       setActiveDraft(latestDraft.id);
      
    //       const previewContent =
    //       latestDraft?.type === "docx" && typeof latestDraft?.preview === "string"
    //         ? latestDraft.preview
    //         : legacyFormatMessage(latestDraft?.text || "");
      
    //       setRenderedDraft(previewContent);
    //     }
    //   }, [drafts]);

    useEffect(() => {
        if (!drafts || drafts.length === 0) {
          setActiveDraft(null);
          setRenderedDraft("");
          return;
        }
      
        if (!activeDraft) {
          const latestDraft = drafts[drafts.length - 1];
          setActiveDraft(latestDraft.id);
      
          const content = getDraftContent(latestDraft);
          if (latestDraft.type === "docx") {
            setRenderedDraft(content); // ✅ no formatting
          } else {
            setRenderedDraft(legacyFormatMessage(content));
          }
        }
      }, [drafts]);
      
      
    // ✅ Ensure renderedDraft updates when user switches draft manually
// useEffect(() => {
//     if (!isEditing && draftText) {
//       setRenderedDraft(legacyFormatMessage(draftText));
//     }
//   }, [activeDraft, isEditing]);

useEffect(() => {
  if (!isEditing && draftText) {
    const content = getDraftContent(activeDraftObj);
    if (activeDraftObj?.type === "docx") {
      setRenderedDraft(content); // ✅ no formatting
    } else {
      setRenderedDraft(legacyFormatMessage(content));
    }
    console.log("🖨️ Final renderedDraft", content);
  }
}, [activeDraft, isEditing]);



  
  function legacyFormatMessage(text) {
    if (typeof text !== "string") {
      console.warn("⚠️ legacyFormatMessage received non-string input:", text);
      return ""; // Or fallback to JSON.stringify(text), or just return raw text
    }
  
    const escapedText = text
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  
    const withMarkdown = escapedText
      .replace(/^### (.*?)$/gm, '<h3 class="text-lg font-bold mt-2">$1</h3>')
      .replace(/^## (.*?)$/gm, '<h2 class="text-xl font-bold mt-4">$1</h2>')
      .replace(/^# (.*?)$/gm, '<h1 class="text-2xl font-bold mt-6">$1</h1>')
      .replace(/\*\*(.*?)\*\*/g, '<strong class="font-semibold">$1</strong>')
      .replace(/\*(.*?)\*/g, '<em class="italic">$1</em>');
  
    const withBreaks = withMarkdown
      .replace(/\n{2,}/g, "<br /><br />")
      .replace(/\n/g, "<br />");
  
    const withHighlights = withBreaks
      .replace(/🟥(.*?)🟥/g, '<span style="background-color: #fee2e2; color: #b91c1c;">$1</span>')
      .replace(/🟩(.*?)🟩/g, '<span style="background-color: #d1fae5; color: #065f46;">$1</span>');
  
    return withHighlights;
  }
  const currentDraft = drafts.find((d) => d.id === activeDraft);

  
  // Combine the global loading prop and the local switchingDraft state
  const isDisplayLoading = loading || switchingDraft;

  const ReviewSuggestion = ({ sentence, onAccept, onDecline }) => (
    <div className="p-4 border rounded shadow-sm bg-white mb-2">
      <p className="text-sm text-gray-500">ประโยคเดิม:</p>
      <p
        className="mb-2 text-sm"
        dangerouslySetInnerHTML={{ __html: legacyFormatMessage(sentence.original) }}
        ></p>
      <p className="text-sm text-gray-500">ข้อเสนอแนะ:</p>
      <p
  className="mb-2 text-sm"
  dangerouslySetInnerHTML={{ __html: legacyFormatMessage(sentence.diff) }}
></p>


      <div className="flex gap-2">
      <button
  onClick={onAccept}
  className="flex items-center gap-1 px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
>
  <Check size={16} /> ยอมรับ
</button>

<button
  onClick={onDecline}
  className="flex items-center gap-1 px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
>
  <X size={16} /> ปฏิเสธ
</button>
      </div>
    </div>
  );

    return (
        <div className="flex flex-col gap-4 w-full">
            {/* 🔄 Show Loading State (Including Version Block Dropdown) */}
            {isDisplayLoading ? (
               <div className="p-4 mt-4 border rounded-md shadow-md bg-gray-200 animate-pulse">
               {/* Placeholder for Draft Version Dropdown */}
               <div className="h-6 bg-gray-300 w-4/4 mb-4 rounded"></div>
             
               {/* Placeholder for Paragraph-like Content */}
               <div className="space-y-2">
                 <div className="h-4 bg-gray-300 rounded w-5/6"></div>
                 <div className="h-4 bg-gray-300 rounded w-4/6"></div>
                 <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                 <div className="h-4 bg-gray-300 rounded w-full"></div>
                 <div className="h-4 bg-gray-300 rounded w-2/3"></div>
                 <div className="h-4 bg-gray-300 rounded w-1/2 mb-6"></div>
                 <div className="h-4 bg-gray-300 rounded w-full"></div>
                 <div className="h-4 bg-gray-300 rounded w-2/3"></div>
             
                 <div className="h-4 bg-gray-300 rounded w-5/6"></div>
                 <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                 <div className="h-4 bg-gray-300 rounded w-4/6"></div>
                 <div className="h-4 bg-gray-300 rounded w-full"></div>
                 <div className="h-4 bg-gray-300 rounded w-2/3"></div>
                 <div className="h-4 bg-gray-300 rounded w-full"></div>
                 <div className="h-4 bg-gray-300 rounded w-2/4"></div>
               </div>
             </div>
             
            ) : (
                <>
                    {/* ✅ Draft Version Dropdown */}


                    {activeDraft && (
                    <div className="relative w-full bg-white border rounded-md shadow-sm">
                        {/* ✅ Combined Header (Dropdown + Actions) */}
                        <div className="sticky top-0 bg-white z-10 border-b p-4 space-y-2">
                        <div className="flex justify-between items-center">
                            <h3 className="text-lg font-bold">ร่างเอกสาร</h3>
                            <ActionButtons
                                draftContent={currentDraft?.path || currentDraft?.text}
                                draftType={
                                    currentDraft?.type === "docx" && currentDraft?.path
                                    ? "form_fill"
                                    : "freeform"
                                }
                                projectId={projectId} //
                                />
                        </div>
                        {/* ✅ Draft Version Dropdown */}
                        <div className ="flex items-center gap-2">
                            <label className="text-sm font-semibold text-gray-700 block mb-1 w-1/4">
                            เลือกเอกสาร:
                            </label>
                            <select
                            className="p-2 border text-sm rounded-md w-full w-3/4"
                            value={activeDraft || ""}
                            onChange={(e) => handleSelectDraft(e.target.value)}
                            >
                            {drafts.map((draft) => (
                                <option key={draft.id} value={draft.id}>
                                {`Draft ${draft.id} - ${new Date(draft.timestamp).toLocaleString()}`}
                                </option>
                            ))}
                            </select>
                        </div>
                        </div>
                    {/* ✅ Scrollable Draft Content */}

                    {/* 1. Draft Display Block */}
                    <div className="max-h-[400px] overflow-y-auto p-4">
                    {activeDraftObj?.type === "docx" && activeDraftObj?.path ? (
                    // ➕ This is a form-fill (PDF preview)
                    <div className="w-full h-[600px] border rounded-md overflow-hidden">
                        <iframe
                        src={`${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/draft-preview/${activeDraftObj.id}`}
                        width="100%"
                        height="100%"
                        title="Draft Preview PDF"
                        />
                    </div>
                    ) : (
                    // ➕ This is a freeform draft
                    <div className="whitespace-pre-wrap text-gray-700 font-sans">
                        <div dangerouslySetInnerHTML={{ __html: renderedDraft }} />
                    </div>
                    )}

                    </div>
                    {/* Shared Container for All Suggestions */}

                    {selectedMode === "review" && (
                        
  <div className="min-h-[250px] max-h-[300px] overflow-auto p-2 mt-4 m-2 border border-gray-300 rounded-md bg-gray-100 shadow-sm">
    {suggestions.length === 0 ? (
      <>
        <h4 className="text-md font-semibold text-center text-gray-700 mb-2">ไม่พบข้อเสนอแนะ</h4>

      </>
    ) :<>
<div className="flex justify-between items-center mb-2">
  <h4 className="text-md font-semibold">
    {isEditing ? "✏️ ข้อเสนอแนะทีละประโยค" : "🧐 ข้อเสนอแนะจากระบบ"}
  </h4>

  <div className="flex items-center gap-2 ml-auto">
    {isEditing && (
      <>
        <button
          onClick={() => setCurrentIndex((prev) => Math.max(0, prev - 1))}
          disabled={currentIndex === 0}
          className="flex items-center gap-1 px-3 py-1 text-sm bg-gray-100 text-gray-700 border border-gray-300 rounded disabled:opacity-50"
        >
          <ChevronLeft size={16} /> ก่อนหน้า
        </button>
        <button
          onClick={() =>
            setCurrentIndex((prev) =>
              Math.min(suggestions.length - 1, prev + 1)
            )
          }
          disabled={currentIndex === suggestions.length - 1}
          className="flex items-center gap-1 px-3 py-1 text-sm bg-gray-100 text-gray-700 border border-gray-300 rounded disabled:opacity-50"
        >
          ถัดไป <ChevronRight size={16} />
        </button>
      </>
    )}
    <button
      onClick={() => setIsEditing(!isEditing)}
      className="px-3 py-1 text-sm bg-gray-100 text-gray-700 border border-gray-300 rounded hover:bg-gray-200"
    >
      {isEditing ? "ดูทั้งหมด" : "เลือกทีละข้อ"}
    </button>
  </div>
</div>

  
    {!isEditing ? (
      <>
        <p className="text-sm text-gray-700 mb-4">
          ระบบได้เสนอการปรับปรุงบางส่วนของร่างนี้ คุณต้องการยอมรับหรือไม่?
        </p>
        <div className="flex justify-start gap-4 mt-4">
  <button
    className="flex items-center gap-2 px-4 py-2 text-sm bg-green-500 text-white rounded hover:bg-green-600 transition"
    onClick={() => {
      onUpdateDraft(activeDraftObj.cleanText);
      setRenderedDraft(legacyFormatMessage(activeDraftObj.cleanText));
      patchDraft(
        activeDraftObj.cleanText,
        activeDraftObj.cleanText,
        activeDraftObj.sentences,
        {} // ✅ Clear suggestionOnly
      );
      setSuggestions([]); // ✅ Clear suggestions in UI
    }}
  >
    <Check size={16} /> ยอมรับทั้งหมด
  </button>

  <button
    className="flex items-center gap-2 px-4 py-2 text-sm bg-red-500 text-white rounded hover:bg-red-600 transition"
    onClick={() => {
      onUpdateDraft(activeDraftObj.original || "");
      setRenderedDraft(legacyFormatMessage(activeDraftObj.original || ""));
      patchDraft(
        activeDraftObj.original,
        activeDraftObj.original,
        activeDraftObj.sentences,
        {} // ✅ Clear suggestionOnly
      );
      setSuggestions([]); // ✅ Clear suggestions in UI
    }}
  >
    <X size={16} /> ปฏิเสธทั้งหมด
  </button>
</div>

      </>
    ) : (
      <>
        {suggestions[currentIndex] && (
          <ReviewSuggestion
            sentence={suggestions[currentIndex]}
            onAccept={() => handleAccept(currentIndex)}
            onDecline={() => handleDecline(currentIndex)}
          />
        )}

      </>
    )}
  </>
  
  }
  </div>
)}




                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default DraftOutput;
