import React from "react";

const SkeletonLoader = ({ type }) => {
  const renderSkeleton = () => {
    if (type === "Output") {
      return (
        <div className="animate-pulse space-y-4">
      <div className="p-4 bg-gray-200 rounded-md shadow-sm">
        <div className="h-6 bg-gray-300 w-1/4 mb-2 rounded"></div>
        <div className="h-40 bg-gray-300 rounded"></div>
        <div className="h-40 bg-gray-300 rounded"></div>
        <div className="h-40 bg-gray-300 rounded"></div>
        <div className="h-40 bg-gray-300 rounded"></div>
      </div>
    </div>
      );
    } else if (type === "References") {
      return (
        <div className="p-4 bg-white border rounded-md shadow-sm">
          <h3 className="text-lg font-bold mb-4">เอกสารกฎหมายที่เกี่ยวข้อง</h3>
          <div className="space-y-4">
              <div className="p-4 border rounded-md shadow-md bg-gray-200 animate-pulse">
                <p className="text-gray-500 text-sm">กำลังค้นหาเอกสารอ้างอิง...</p>
              </div>
              <div className="p-4 border rounded-md shadow-md bg-gray-200 animate-pulse">
                <p className="text-gray-500 text-sm">กำลังค้นหาเอกสารอ้างอิง...</p>
              </div>
              <div className="p-4 border rounded-md shadow-md bg-gray-200 animate-pulse">
                <p className="text-gray-500 text-sm">กำลังค้นหาเอกสารอ้างอิง...</p>
              </div>
            </div>
       </div>
      );
    }
  };

  return <>{renderSkeleton()}</>;
};

export default SkeletonLoader;
