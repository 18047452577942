import React, { useState, useEffect } from "react";
import { ChevronDown,ChevronUp } from "lucide-react";


const FilesOutput = ({textData, internalDocuments, loading }) => {
  const [showFullCard, setShowFullCard] = useState({});
  const [showCaseLaw, setShowCaseLaw] = useState(true); // Toggle for "คำพิพากษาฎีกา"
  const [showOtherDocs, setShowOtherDocs] = useState(true); // Toggle for other documents

  // Legacy formatting function
  function legacyFormatMessage(text) {
    return text
      .replace(/^### (.*?)$/gm, '<h3 class="text-lg font-bold mt-2">$1</h3>')
      .replace(/^## (.*?)$/gm, '<h2 class="text-xl font-bold mt-4">$1</h2>')
      .replace(/^# (.*?)$/gm, '<h1 class="text-2xl font-bold mt-6">$1</h1>')
      .replace(/\*\*(.*?)\*\*/g, '<strong class="font-semibold">$1</strong>')
      .replace(/\*(.*?)\*/g, '<em class="italic">$1</em>')
      .replace(/\n{2,}/g, "<br /><br />")
      .replace(/\n/g, "<br />").replace(/\xa0/g, '&nbsp;');
  }

  
    // ✅ Group documents into case law and other legal documents
    const caseLawDocs = internalDocuments.filter(doc => doc.title?.includes("คำพิพากษาศาลฎีกา"));
    const otherDocs = internalDocuments.filter(doc => !doc.title?.includes("คำพิพากษาศาลฎีกา"));



  // ✅ Use unique keys (case_0, law_0, etc.) to prevent conflicts
  const handleCardToggle = (key) => {
    setShowFullCard((prev) => ({
      ...prev,
      [key]: !prev[key], // ✅ Toggle only the clicked item
    }));
  };
  
  if (!textData && (!internalDocuments || internalDocuments.length === 0)) {
    return <p className="text-center text-gray-500">No data available.</p>;
  }

  return (
    <div className="flex flex-col gap-4">
      {/* Original Text Section */}
      {textData?.text && (
        <div
          className="p-4 bg-white border rounded-md shadow-sm hover:shadow-md transition-shadow "
        >
          <h5 className="font-medium text-lg mb-2">เอกสารกฎหมายที่เกี่ยวข้อง</h5>
            <div
              className="text-sm text-gray-700 mt-4 overflow-y-auto max-h-450"
              dangerouslySetInnerHTML={{
                __html: legacyFormatMessage(textData.text),
              }}
            />
        </div>
      )}

        {internalDocuments.length > 0 && (
        <div className="p-4 bg-white border rounded-md shadow-sm">
          <h3 className="text-lg font-bold mb-4">เอกสารกฎหมายที่เกี่ยวข้อง</h3>

          {/* 🔹 Section: คำพิพากษาฎีกา */}
          {caseLawDocs.length > 0 && (
          <div className="mb-4 border p-3 rounded-md shadow-md">
            {/* Clickable Header for Expanding/Collapsing */}
            <div 
              className="flex justify-between items-center cursor-pointer" 
              onClick={() => setShowCaseLaw(!showCaseLaw)}
            >
              <h4 className="text-md font-bold">คำพิพากษาฎีกา ({caseLawDocs.length})</h4>
              {showCaseLaw ? <ChevronUp /> : <ChevronDown />}
            </div>

            {/* Smooth Expand/Collapse */}
            <div
              className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${
                showCaseLaw ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
              }`}
              style={{
                maxHeight: showCaseLaw ? "1000px" : "0px",
              }}
            >
              <div className="mt-2 space-y-2">
                {caseLawDocs.map((doc, index) => {
                  const uniqueKey = `case_${index}`;

                  return (
                    <div 
                      key={uniqueKey} 
                      className="p-3 border rounded-md shadow-sm bg-white cursor-pointer hover:bg-gray-200 transition-all duration-300 ease-in-out"
                      onClick={() => handleCardToggle(uniqueKey)}
                    >
                      <div className="flex items-center justify-between">
                        <p className="font-bold text-sm">{doc.title || `Document ${index + 1}`}</p>
                        {showFullCard[uniqueKey] ? <ChevronUp className="text-gray-500" /> : <ChevronDown className="text-gray-500" />}
                      </div>

                      {/* Smooth Expand & Collapse for Inner Card */}
                      <div
                        className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${
                          showFullCard[uniqueKey] ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
                        }`}
                        style={{
                          maxHeight: showFullCard[uniqueKey] ? "500px" : "0px",
                        }}
                      >
                        <p 
                          className="text-sm mt-2 transition-opacity duration-300 ease-in-out"
                          dangerouslySetInnerHTML={{ __html: legacyFormatMessage(doc.content || "No additional info available") }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

      {/* 🔹 Section: Other Legal Documents */}
      {otherDocs.length > 0 && (
        <div className="border p-3 rounded-md shadow-md">
          {/* Clickable Header for Expanding/Collapsing */}
          <div 
            className="flex justify-between items-center cursor-pointer" 
            onClick={() => setShowOtherDocs(!showOtherDocs)}
          >
            <h4 className="text-md font-bold">มาตรากฎหมาย ({otherDocs.length})</h4>
            {showOtherDocs ? <ChevronUp /> : <ChevronDown />}
          </div>

          {/* Smooth Expand/Collapse */}
          <div
            className={`overflow-hidden transition-[max-height] duration-500 ease-in-out ${
              showOtherDocs ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
            }`}
            style={{
              maxHeight: showOtherDocs ? "1000px" : "0px",
            }}
          >
            <div className="mt-2 space-y-2">
              {otherDocs.map((doc, index) => {
                const uniqueKey = `law_${index}`;

                return (
                  <div 
                    key={uniqueKey} 
                    className="p-3 border rounded-md shadow-sm bg-white cursor-pointer hover:bg-gray-200 transition-all duration-500 ease-in-out"
                    onClick={() => handleCardToggle(uniqueKey)}
                  >
                    <div className="flex items-center justify-between">
                      <p className="font-bold text-sm">{doc.title || `Document ${index + 1}`}</p>
                      {showFullCard[uniqueKey] ? <ChevronUp className="text-gray-500" /> : <ChevronDown className="text-gray-500" />}
                    </div>

                    {/* Smooth Expand & Collapse for Inner Card */}
                    <div
                      className={`overflow-hidden transition-[max-height] duration-500 ease-in-out ${
                        showFullCard[uniqueKey] ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
                      }`}
                      style={{
                        maxHeight: showFullCard[uniqueKey] ? "500px" : "0px",
                      }}
                    >
                      <p 
                        className="text-sm mt-2 transition-opacity duration-500 ease-in-out"
                        dangerouslySetInnerHTML={{ __html: legacyFormatMessage(doc.content || "No additional info available") }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}



      {/* 🔄 Show Loading State */}
              {loading && (
          <div className="p-4 mt-4 border rounded-md shadow-md bg-gray-200 space-y-2">
            <div className="h-4 bg-gray-300 rounded w-3/4 animate-pulse"></div>
            <div className="h-4 bg-gray-300 rounded w-2/3 animate-pulse"></div>
          </div>
        )}
        </div>
      )}

  
  {/* ✅ Show "No data" only when there's really nothing */}
  {!loading && !textData?.text && (!internalDocuments || internalDocuments.length === 0) && (
        <p className="text-center text-gray-500">No data available.</p>
      )}
    </div>
  );
};

export default FilesOutput;
