import React, { useState,useEffect,useRef } from "react";
import { Lock, AudioLines, Globe, Upload, FileText, X, ArrowUp } from "lucide-react";
import Typewriter from "typewriter-effect"; // Import the Typewriter library
import axios from './axiosConfig';
import LegalAnalysisTable from "./LegalAnalysisTable";
import "./index.css"; // Ensure this is correctly placed in your project
import { useLocation, useNavigate,useParams } from "react-router-dom"; // ✅ Correct import

const CommandPanel = ({
  className,
  onCommand,
  activeTabId,
  onFileUpload,
  uploadedFiles,
  onClearFile, 
  updateChat,
  projectId,
  setProjectId,
  messages, // ✅ Receive messages from AppLayout
  setMessages, // ✅ Receive function to update messages
  activeDraft,
  drafts,
  selectedMode, 
  setSelectedMode,
}) => {
  const { projectId: urlProjectId } = useParams(); // ✅ Get projectId from URL
  const [input, setInput] = useState("");
  const [isThinking, setIsThinking] = useState(false); 
  const [thinkingMessageId, setThinkingMessageId] = useState(null); // ✅ Track the current thinking message
  const [isDragOver, setIsDragOver] = useState(false);
  const [isSending, setIsSending] = useState(false); // Track if a message is being sent
  const [finalAnswerDisplayed, setFinalAnswerDisplayed] = useState({});
  const chatEndRef = useRef(null);
  const previousMessageCount = useRef(0);
  const [draftType, setDraftType] = useState("free-form");
  const [selectedFormTemplate, setSelectedFormTemplate] = useState(""); // e.g. "complaint", "petition", etc.
  const [showFormDropdown, setShowFormDropdown] = useState(false);


  // const [selectedMode, setSelectedMode] = useState("chat"); // Default to "search"


  useEffect(() => {
    if (messages.length > previousMessageCount.current) {
      // Only scroll when a new message was added
      if (chatEndRef.current) {
        chatEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
    previousMessageCount.current = messages.length; // Update the last count
  }, [messages]);
  
  

  
  useEffect(() => {
    const storedState = localStorage.getItem("finalAnswerDisplayed");
    if (storedState) {
      setFinalAnswerDisplayed(JSON.parse(storedState));
    }
  }, []); // ✅ Load only once when component mounts


  // useEffect(() => {
  //   if (!projectId || messages.length === 0) return;
  
  //   console.log("✅ Now saving message with projectId:", projectId);
  //   saveMessageToProject(messages[messages.length - 1]); // Save the latest message
  // }, [projectId]);  // ✅ Runs only when `projectId` updates
  
  useEffect(() => {
    if (!projectId || messages.length === 0) return;
  
    const latestMessage = messages[messages.length - 1];
  
    if (latestMessage && !latestMessage.saved) {
      console.log("✅ Saving only unsaved messages:", latestMessage);
      saveMessageToProject({ ...latestMessage, saved: true }); // Mark as saved
    }
  }, [projectId]);  // ✅ Runs only when `projectId` updates
  
  
  const navigate = useNavigate();
  const location = useLocation(); // ✅ Fix: Correctly use useLocation
  const ongoingRequest = useRef(false);

  // load, update, save, and, delete the project
  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    console.log("CommandPanel.js Project ID:", localStorage.getItem("projectId"));
  }, []);

  useEffect(() => {
    console.log("🔥 Messages Updated:", messages);
  }, [messages]);
 
useEffect(() => {
  if (!urlProjectId) {
    console.log("🛑 No projectId in URL. Resetting chat history.");
    setMessages([]); // ✅ Clear messages when no project ID
    return;
  }

  console.log("🔄 Fetching chat history for project:", urlProjectId);

  // ✅ Track ongoing requests to prevent overlap


  // ✅ Define an async function inside useEffect (useEffect itself cannot be async)
  const fetchProjectData = async () => {
    if (ongoingRequest.current) {
      console.log("⚠️ Skipping duplicate request, one is already in progress.");
      return; // ✅ Prevent duplicate requests
    }

    ongoingRequest.current = true; // ✅ Mark request as in progress

    try {
      // ✅ Prevent using an outdated auth token
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        console.error("🚨 No auth token found. Skipping request.");
        ongoingRequest.current = false; // ✅ Reset flag on failure
        return;
      }

      console.log(`📡 Sending request for project ${urlProjectId}`);

      // ✅ Parallel API request execution (Future-proofing)
      const [response] = await Promise.all([
        axios.get(`/api/projects/${urlProjectId}/messages`, {
          headers: { Authorization: `Bearer ${authToken}` },
        }),
      ]);

      console.log("📥 Loaded project messages:", response.data);

      // ✅ Handle empty response safely
      const backendMessages = response.data || [];

      // ✅ Convert messages to a map for efficient processing
      const messageMap = backendMessages.reduce((acc, msg) => {
        acc[msg.id] = { ...msg, subMessages: msg.subMessages || [] };
        return acc;
      }, {});

      // ✅ Rebuild message structure
      const groupedMessages = backendMessages
        .map((msg) => {
          if (msg.id.startsWith("bot-")) return messageMap[msg.id];
          if (msg.id.startsWith("sub-")) {
            const parentBotMessage = messageMap[msg.parent_bot_id];
            if (parentBotMessage) {
              parentBotMessage.subMessages.push(messageMap[msg.id]);
            }
          }
          return messageMap[msg.id];
        })
        .filter(Boolean); // ✅ Remove any undefined elements

      console.log("✅ Processed Messages After Rebuilding:", groupedMessages);

      // ✅ Restore pending bot message if no messages exist
      if (groupedMessages.length === 0) {
        const pendingBotMessage = sessionStorage.getItem("pendingBotMessage");
        if (pendingBotMessage) {
          console.log("🔄 Restoring pending bot message...");
          setMessages([JSON.parse(pendingBotMessage)]);
          sessionStorage.removeItem("pendingBotMessage"); // ✅ Clear after restoring
        }
      } else {
        setMessages(groupedMessages);
      }

      // ✅ Restore pending user message (if exists)
      const pendingMessage = sessionStorage.getItem("pendingMessage");
      if (pendingMessage) {
        console.log("🔄 Restoring pending user message:", JSON.parse(pendingMessage));
        setMessages((prevMessages) => [...prevMessages, JSON.parse(pendingMessage)]);
        sessionStorage.removeItem("pendingMessage"); // ✅ Clear after restoring
      }
    } catch (error) {
      console.error("🚨 Error fetching chat history:", error);
    } finally {
      ongoingRequest.current = false; // ✅ Allow next request
    }
  };

  fetchProjectData(); // ✅ Fire and forget: Doesn't block UI

  // ✅ Cleanup function to avoid state updates on unmounted component
  return () => {
    console.log("🛑 Cleanup: Cancelling API request if component unmounts or project changes.");
    ongoingRequest.current = false; // ✅ Reset on unmount
  };
}, [urlProjectId]); // ✅ Dependencies: only re-run when `urlProjectId` changes


  const generateProjectTitle = async (userQuery) => {
    try {
      const response = await axios.get(`/api/generate_project_title`, {
        params: { user_query: userQuery }, // ✅ Send query parameter correctly
      });
  
      return response.data.title || "New Chat Project"; // Fallback title
    } catch (error) {
      console.error("🚨 Error generating project title:", error);
      return "New Chat Project"; // Fallback if API fails
    }
  };
  

  const createProject = async (userQuery) => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        console.error("🚨 No auth token found!");
        return null;
      }
  
      // 🔥 Step 1: Get AI-generated project title from user query
      console.log("📡 Generating project title for:", userQuery);
      const projectTitle = await generateProjectTitle(userQuery);
  
      console.log("📡 Creating new project with title:", projectTitle);
      console.log("📡 Sending API request to create project with title:", projectTitle);
  
      // 🔥 Step 2: Create the project
      const response = await axios.post(
        `/api/projects?title=${encodeURIComponent(projectTitle)}`,
        {}, // ✅ Empty body, since title is in the URL
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
  
      console.log("✅ Project created:", response.data);
      const newProjectId = response.data.id;
  
      if (!newProjectId) {
        console.error("🚨 Error: No Project ID received from API.");
        return null;
      }


      // localStorage.setItem("projectId", newProjectId);
      sessionStorage.setItem("projectId", newProjectId); // 🔥 Use sessionStorage instead
      setProjectId(newProjectId);

      return newProjectId;
    } catch (error) {
      console.error("🚨 Error creating project:", error.response?.data || error.message);
      return null;
    }
  };
  
  
  const saveMessageToProject = async (message, parentBotId = null) => {
    try {
        const authToken = localStorage.getItem("authToken");
        // const projectId = localStorage.getItem("projectId");
        const projectId = sessionStorage.getItem("projectId"); // 🔥 Use sessionStorage instead


        if (!authToken || !projectId) {
            console.error("🚨 Missing auth token or project ID! Aborting save.");
            return;
        }

        let url = `/api/projects/${projectId}/messages`;

        let payload = { ...message };

        if (parentBotId) {
            // ✅ Attach subquery inside existing bot message
            payload.parent_bot_id = parentBotId;
            await axios.patch(url, payload, {
                headers: { Authorization: `Bearer ${authToken}`, "Content-Type": "application/json" },
            });
            console.log(`✅ Subquery attached to bot message: ${parentBotId}`);
        } else {
            // ✅ Ensure that the bot message updates instead of creating a new one
            await axios.patch(url, payload, {
                headers: { Authorization: `Bearer ${authToken}`, "Content-Type": "application/json" },
            });
            console.log(`✅ Message ${message.id} updated successfully`);
        }
    } catch (error) {
        console.error("🚨 Error saving message:", error.response?.data || error.message);
    }
};




// // 🔥 **Load Existing Project on Page Load**

// useEffect(() => {
//   const urlProjectId = location.pathname.split("/project/")[1]; // Extract ID from URL
//   if (urlProjectId && urlProjectId !== projectId) {
//     console.log("🔄 Updating projectId from URL:", urlProjectId);
    
//     setProjectId(urlProjectId);
//     localStorage.setItem("projectId", urlProjectId);

//     // ✅ Wait for state update before proceeding
//     setTimeout(() => {
//       console.log("✅ Project ID now set:", urlProjectId);
//     }, 100);  // Small delay to allow state update
//   }
// }, [location.pathname]); // ✅ Re-run when URL changes

useEffect(() => {
  const urlProjectId = location.pathname.split("/project/")[1]; // Extract ID from URL

  if (urlProjectId) {
    console.log("🔄 Updating projectId from URL:", urlProjectId);
    setProjectId(urlProjectId);
    localStorage.setItem("projectId", urlProjectId); // ✅ Only store if there's a valid ID
    sessionStorage.setItem("projectId", urlProjectId); // ✅ Use sessionStorage instead

  } else {
    console.log("🛑 No projectId in URL. Clearing stored ID.");
    setProjectId(null); // ✅ Start fresh
    localStorage.removeItem("projectId"); // ✅ Clear stored ID if no project is in the URL
    sessionStorage.removeItem("projectId"); // 🔥 Use sessionStorage instead
  }
}, [location.pathname]); // ✅ Runs when the URL changes




  // Legacy formatting function
  function legacyFormatMessage(text) {
    return text
      // Headers
      .replace(/^### (.*?)$/gm, '<h3 class="text-lg font-bold mt-2">$1</h3>')
      .replace(/^## (.*?)$/gm, '<h2 class="text-xl font-bold mt-4">$1</h2>')
      .replace(/^# (.*?)$/gm, '<h1 class="text-2xl font-bold mt-6">$1</h1>')
  
      // Bold and italic
      .replace(/\*\*(.*?)\*\*/g, '<strong class="font-semibold">$1</strong>')
      .replace(/\*(.*?)\*/g, '<em class="italic">$1</em>')
  
      // Horizontal line
      .replace(/^---$/gm, '<hr class="my-4 border-t border-gray-300" />')
  
      // Highlights with emojis
      .replace(/🟥(.*?)🟥/g, '<span style="background-color: #fee2e2; color: #b91c1c;">$1</span>')
      .replace(/🟩(.*?)🟩/g, '<span style="background-color: #d1fae5; color: #065f46;">$1</span>')
  
      // Line breaks
      .replace(/\n{2,}/g, "<br /><br />")
      .replace(/\n/g, "<br />")
  
      // Non-breaking space
      .replace(/\xa0/g, '&nbsp;');
  }
  

  // Auto-collapse when finalAnswer is generated
useEffect(() => {
  setMessages((prevMessages) =>
    prevMessages.map((msg) =>
      msg.finalAnswer ? { ...msg, expanded: false } : msg
    )
  );
}, [messages.some((msg) => msg.finalAnswer)]); // Trigger when final answer appears


const handleSendMessage = async () => {
  if (!input.trim() && uploadedFiles.length === 0) {
    alert("Please provide a query or upload at least one file.");
    return;
  }
  
  

  if (isSending) return; // ✅ Prevent double submission
  // **🔥 Step 1: Determine User Intention (Search or Draft)**
  const userIntention = selectedMode;
  console.log(`✅ Selected Mode: ${userIntention}`);
  const intentionThinkingMap = {
    chat: "💬 กำลังคิดคำตอบให้...",
    search: "🔍 กำลังค้นหากฎหมายที่เกี่ยวข้อง...",
    draft: "✍️ กำลังร่างเอกสารตามคำสั่ง...",
    review: "🧐 กำลังตรวจสอบและแก้ไขเอกสาร...",
  };
  
  const thinkingMessage = intentionThinkingMap[userIntention] || "🤔 กำลังดำเนินการ...";

  
    

  setIsSending(true); // ✅ Set sending flag immediately
  setInput("");
  const textarea = document.querySelector("textarea");
  if (textarea) {
    textarea.style.height = "48px"; // ✅ Reset to default height
  }

  let currentProjectId = localStorage.getItem("projectId");
  console.log("✅ Now saving message with projectId:", currentProjectId);
  console.log("📡 Checking projectId before message:", currentProjectId);



  if (messages.length === 0) {
    currentProjectId = await createProject(input.trim());
    if (!currentProjectId) {
      console.error("🚨 Project creation failed! currentProjectId is null.");
      return;
    }
  
    localStorage.setItem("projectId", currentProjectId);
    await setProjectId(currentProjectId);
    navigate(`/project/${currentProjectId}`);
  

  }
  
  // ✅ Step 1: Instantly show user message in UI
const userMessage = {
  id: `user-${Date.now()}`,
  sender: "user",
  text: input.trim(),
  timestamp: new Date().toISOString(),
  saved: true, // ✅ Prevent it from being saved again in useEffect
};

setMessages((prevMessages) => [...prevMessages, userMessage]); // ✅ No delay in UI

  // ✅ Step 2: Save user message FIRST (ensures sequential order)
  await saveMessageToProject(userMessage);

  // ✅ Step 3: If there's a file, create & save file message NEXT (maintain order)
  if (uploadedFiles && uploadedFiles.length > 0) {
    for (const file of uploadedFiles) {
      const fileMessage = {
        id: `file-${Date.now()}-${file.name}`,
        sender: "user",
        text: `📎 ไฟล์ประกอบ: ${file.name}`,
        timestamp: new Date().toISOString(),
      };
      setMessages((prevMessages) => [...prevMessages, fileMessage]);
      await saveMessageToProject(fileMessage);
    }
  }
  
  

  // ✅ Step 4: Instantly show bot message in UI
  const botMessage = {
    id: `bot-${Date.now()}`,
    sender: "bot",
    text: `<span class='shiny-text'>${thinkingMessage}</span>`,
    subqueries: [],
    finalAnswer: "",
    timestamp: new Date().toISOString(),
    expanded: true,
  };

  setMessages((prevMessages) => [...prevMessages, botMessage]); // ✅ No delay in UI

  // ✅ Step 5: Save bot message LAST (ensures correct order)
  await saveMessageToProject(botMessage);




  setFinalAnswerDisplayed({});
  localStorage.removeItem("finalAnswerDisplayed"); // ✅ Reset storage when new chat starts


  setIsThinking(true);
  // ✅ Initialize `internalDocuments` outside the loop
  let internalDocuments = [];

  try {
    // **🔥 Step 0: Determine User Intention**
    const formData = new FormData();
    formData.append("query", input.trim());
    formData.append("project_id", currentProjectId);

    if (uploadedFiles && uploadedFiles.length > 0) {
      uploadedFiles.forEach((file) => {
        formData.append("files", file); // "files" must match your FastAPI backend field
      });
    }
    

// ✅ If **Draft**, send the request to `AppLayout.js` and return
if (userIntention === "draft") {
  console.log("✍️ Forwarding Draft Command to AppLayout.js");


// **🔥 Send draft command to `AppLayout.js`**
const { chatResponse, draft } = await onCommand(
  {
    query: input.trim(),
    files: uploadedFiles,
    formType: draftType === "form-fill" ? selectedFormTemplate : "", // ✅ Only send if form-fill
  },
  activeTabId,
  "draft"
);

let previewText = "🚨 AI did not return a response.";
if (typeof draft === "string") {
  // Free-form response
  previewText = draft;
} else if (draft?.type === "docx") {
  // Form-fill response with preview
  previewText = draft.preview || "⚠️ ไม่สามารถแสดงพรีวิวเอกสารได้";
}

// ✅ Step 3: Update the **same message** instead of adding a new one
setMessages((prevMessages) =>
  prevMessages.map((msg) =>
    msg.id === botMessage.id
      ? {
          ...msg,
          text: chatResponse || "🚨 AI did not return a response.",
          finalAnswer: previewText,
          expanded: false,
        }
      : msg
  )
);

// ✅ Step 4: Save the updated message to backend
await saveMessageToProject({
  ...botMessage,
  text: chatResponse || "🚨 AI did not return a response.",
  finalAnswer: previewText,
  expanded: false,
  saved: true,
});


  setIsThinking(false);
  setIsSending(false);
  return;
}

if (userIntention === "review") {
  console.log("🧐 Forwarding Review Command to AppLayout.js");
  const selectedDraft = drafts.find((d) => d.id === activeDraft);
  console.log("📄 Active Draft Object:", selectedDraft);
const draft = drafts.find((d) => d.id === activeDraft);
// ✅ Normalize undefined/null to empty array and ensure it's an array
const hasSuggestions =
  Array.isArray(draft?.suggestionOnly) && draft.suggestionOnly.length > 0 ||
  Array.isArray(draft?.suggestions) && draft.suggestions.length > 0;

if (hasSuggestions) {
  const warningMessage = {
    ...botMessage,
    text: "⚠️ ไม่สามารถตรวจร่างที่ได้รับการตรวจแล้วอีกครั้งได้ กรุณาล้างผลการตรวจสอบก่อน",
    finalAnswer: "⚠️ ไม่สามารถตรวจร่างที่ได้รับการตรวจแล้วอีกครั้งได้ กรุณาล้างผลการตรวจสอบก่อน",
    expanded: false,
    saved: true,
  };

  setMessages((prevMessages) =>
    prevMessages.map((msg) => (msg.id === botMessage.id ? warningMessage : msg))
  );

  await saveMessageToProject(warningMessage);

  setIsThinking(false);
  setIsSending(false);
  return;
}



const selectedDraftText = draft?.cleanText || draft?.text || "";


  // ✅ Block if no draft and no uploaded file
  if ((!uploadedFiles || uploadedFiles.length === 0) && !selectedDraftText.trim()) {
    const errorMessage = {
      ...botMessage,
      text: "⚠️ กรุณาร่างเอกสาร หรือ อัปโหลดไฟล์ก่อนใช้งานฟีเจอร์ตรวจสอบและแก้ไข",
      finalAnswer: "⚠️ กรุณาร่างเอกสาร หรือ อัปโหลดไฟล์ก่อนใช้งานฟีเจอร์ตรวจสอบและแก้ไข",
      expanded: false,
      saved: true,
    };
  
    setMessages((prevMessages) =>
      prevMessages.map((msg) => (msg.id === botMessage.id ? errorMessage : msg))
    );
  
    // ✅ Save to project
    await saveMessageToProject(errorMessage);
  
    setIsThinking(false);
    setIsSending(false);
    return;
  }
  
  console.log("Here is the selected draft",selectedDraftText);

  const { chatResponse } = await onCommand(
    {
      query: input.trim(),
      files: uploadedFiles,  // ✅ NEW: send all uploaded files
      text: (!uploadedFiles || uploadedFiles.length === 0) ? selectedDraftText : null, // ✅ use text only if no file
    },
    activeTabId,
    "review"
  );
  
  setMessages((prevMessages) =>
    prevMessages.map((msg) =>
      msg.id === botMessage.id
        ? {
            ...msg,
            text: chatResponse || "🚨 ระบบไม่สามารถแสดงผลลัพธ์ได้",
            finalAnswer: chatResponse,
            expanded: false
          }
        : msg
    )
  );

  await saveMessageToProject({
    ...botMessage,
    text: chatResponse || "🚨 ระบบไม่สามารถแสดงผลลัพธ์ได้",
    finalAnswer: chatResponse,
    expanded: false,
    saved: true
  });

  setIsThinking(false);
  setIsSending(false);
  return;
}

if (userIntention === "chat") {
  console.log("💬 Forwarding General Chat Command to AppLayout.js");

  const { chatResponse } = await onCommand(
    { query: input.trim() },
    activeTabId,
    "chat"
  );

  setMessages((prevMessages) =>
    prevMessages.map((msg) =>
      msg.id === botMessage.id
        ? {
            ...msg,
            text: chatResponse || "🚨 ระบบไม่สามารถแสดงผลลัพธ์ได้",
            finalAnswer: chatResponse,
            expanded: false
          }
        : msg
    )
  );

  await saveMessageToProject({
    ...botMessage,
    text: chatResponse || "🚨 ระบบไม่สามารถแสดงผลลัพธ์ได้",
    finalAnswer: chatResponse,
    expanded: false,
    saved: true
  });

  setIsThinking(false);
  setIsSending(false);
  return;
}




    setThinkingMessageId(botMessage.id); // ✅ Track the bot message that is currently thinking
    await saveMessageToProject(botMessage);

    

    // // ✅ Get Subqueries
    // const decompositionResponse = await axios.post(
    //   "/query-decomposition",
    //   { query: input.trim() },
    //   {
    //     params: { project_id: currentProjectId },
    //     headers: { "Content-Type": "application/json" },
    //   }
    // );
  
    const decompositionResponse = await axios.post(
      "/query-decomposition",
      formData, // 🟢 Reuse your already prepared FormData
      {
        params: { project_id: currentProjectId },
        headers: { "Content-Type": "multipart/form-data" }, // required for file upload
      }
    );
    
    let subqueries = decompositionResponse.data?.subqueries || [input.trim()];

    let subThoughts = [];
    let allInternalDocuments = [];  // ✅ Store all internal references


    for (let i = 0; i < subqueries.length; i++) {
      let subquery = subqueries[i];

      // ✅ Get AI Intention & Reasoning
      const intentionResponse = await axios.post(
        "/search-intention",
        { query: subquery, document: false },  // ✅ Pass data in body
        { 
            params: { project_id: currentProjectId },  // ✅ Pass project_id as a query param
            headers: { "Content-Type": "application/json" }
        }
      );
      let commandType = intentionResponse.data?.intention || "unknown";
      let reasoning = intentionResponse.data?.reasoning || "▼ กำลังคิด";

      console.log(`✅ Processing subquery: ${subquery}, Intention: ${commandType}`);

      // ✅ Create Subquery Object
      let subMessage = {
        id: `sub-${i}`,
        text: `${subquery}`,
        response: reasoning,
      };

      // ✅ Append to bot message
      botMessage.subqueries.push(subMessage);
      // setMessages((prevMessages) => prevMessages.map((msg) => (msg.id === botMessage.id ? botMessage : msg)));
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === botMessage.id
            ? {
                ...botMessage,
                expanded: true, // ✅ Keep main bot message expanded
                subqueries: botMessage.subqueries.map((s, idx) => ({
                  ...s,
                  expanded: idx === botMessage.subqueries.length - 1, // ✅ Expand only latest subquery
                })),
              }
            : msg
        )
      );
      
      await saveMessageToProject(botMessage); // ✅ Save updated bot message

      // ✅ Get AI response for subquery
      const response = await onCommand({ query: subquery, reasoning }, activeTabId, commandType);
      console.log("📥 Received response from onCommand:", response);

      const chatResponse = response?.chatResponse || "No response received.";
      const internalDocuments = response?.internalDocuments || [];  // ✅ Extract references

      console.log(`✅ Processed Subquery Response:`, {
        subquery: subquery,
        commandType,
        chatResponse,
        internalDocuments,  // ✅ Debugging to confirm received references
      });

        // ✅ Accumulate all internal documents
      allInternalDocuments.push(...internalDocuments);  // ✅ Append new references
      allInternalDocuments = Array.from(new Map(allInternalDocuments.map(doc => [doc.title, doc])).values());  // ✅ Remove duplicates


      // ✅ Update Subquery Response
      subMessage.response = `${reasoning}\n\n📌 ${chatResponse}`;
      subThoughts.push({ subquery, answer: chatResponse });

      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === botMessage.id
            ? {
                ...botMessage,
                expanded: true, // ✅ Ensure bot message stays expanded
                subqueries: botMessage.subqueries.map((s, idx) => ({
                  ...s,
                  expanded: idx === botMessage.subqueries.length - 1, // ✅ Expand only the latest subquery
                })),
              }
            : msg
        )
      );
      
      
      await saveMessageToProject(botMessage); // ✅ Save updated bot message
    }

    

    // ✅ Generate Final Summary
    const summaryResponse = await axios.post(
      "/final-summary",
      // { subqueries, answers: subThoughts },  // ✅ Pass data in body
      { 
        query: input.trim(),  // ✅ Include the main user query
        subqueries, 
        answers: subThoughts,
        internal_documents: allInternalDocuments || [],  // ✅ Ensure it's always a valid list
      }, 
      { 
          params: { project_id: currentProjectId },  // ✅ Pass project_id as a query param
          headers: { "Content-Type": "application/json" }
      }
    );
    const finalSummary = summaryResponse.data?.summary || "🚨 Summary generation failed.";
    const references = summaryResponse.data?.references || []; // ✅ Extract references from API response
    const legalTable = summaryResponse.data?.legal_analysis_table || [];


    botMessage.finalAnswer = finalSummary;
    botMessage.references = references; // ✅ Store references in botMessage
    botMessage.legalAnalysisTable = legalTable;
    
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === botMessage.id
          ? {
              ...msg,
              finalAnswer: finalSummary,
              references: references, // ✅ Store references in messages
              legalAnalysisTable: legalTable,
              expanded: false, // ✅ Collapse outer bot message after final answer
            }
          : msg
      )
    );

    await saveMessageToProject(botMessage); // ✅ Save Final Summary

    setThinkingMessageId(botMessage.id); // ✅ Set the final answer to animate ONLY for the last message
    setIsThinking(false);
  } catch (error) {
    console.error("🚨 Error:", error);
    setThinkingMessageId("🚨 Error: Something went wrong.");
  } finally {
    setIsSending(false);
    onClearFile();
  }
  setInput(""); // ✅ Clear input only after send is completed
};


  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const allowedExtensions = [".txt", ".png", ".jpg", ".pdf", ".doc", ".docx"];

const isValidFileType = (file) => {
  const fileName = file.name.toLowerCase();
  return allowedExtensions.some((ext) => fileName.endsWith(ext));
};

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   setIsDragOver(false);
  
  //   if (e.dataTransfer.files.length > 0) {
  //     const file = e.dataTransfer.files[0];
  
  //     if (!file) {
  //       console.error("No file found in drop event.");
  //       return;
  //     }
  
  //     if (!isValidFileType(file)) {
  //       alert("❌ ไฟล์ไม่ถูกต้อง โปรดอัปโหลดไฟล์ประเภท .txt, .png, .jpg, .pdf, .doc หรือ .docx เท่านั้น");
  //       return;
  //     }
  
  //     console.log("Dropped file:", file);
  //     onClearFile(); // ✅ Clear previous file first
  
  //     setTimeout(() => {
  //       onFileUpload(file); // ✅ Ensure new file re-renders
  //     }, 100);
  //   }
  // };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  
    const files = Array.from(e.dataTransfer.files);
    const validFiles = files.filter(isValidFileType);
  
    if (validFiles.length !== files.length) {
      alert("❌ บางไฟล์ไม่ถูกต้อง (รองรับเฉพาะ .txt, .png, .jpg, .pdf, .doc, .docx)");
    }
  
    if (validFiles.length > 0) {
      onFileUpload(validFiles);
    }
  };
  
  
  

  // const handleFileSelect = (e) => {
  //   if (e.target.files.length > 0) {
  //     const file = e.target.files[0];
  
  //     if (!file) {
  //       console.error("No file selected.");
  //       return;
  //     }
  
  //     if (!isValidFileType(file)) {
  //       alert("❌ ไฟล์ไม่ถูกต้อง โปรดอัปโหลดไฟล์ประเภท .txt, .png, .jpg, .pdf, .doc หรือ .docx เท่านั้น");
  //       return;
  //     }
  
  //     console.log("Selected file:", file);
  //     onClearFile(); // ✅ Clear previous file first
  
  //     // ✅ Reset the file input field
  //     e.target.value = null; // 🚀 Important to allow re-uploading the same file
  
  //     setTimeout(() => {
  //       onFileUpload(file); // ✅ Ensure file re-renders properly
  //     }, 100); // ✅ Small delay ensures state updates correctly
  //   }
  // };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(isValidFileType);
  
    if (validFiles.length !== files.length) {
      alert("❌ บางไฟล์ไม่ถูกต้อง (รองรับเฉพาะ .txt, .png, .jpg, .pdf, .doc, .docx)");
    }
  
    if (validFiles.length > 0) {
      onFileUpload(validFiles); // ✅ Send all valid files
    }
  
    e.target.value = null; // ✅ Allow re-selecting the same files
  };
  

  const formatFinalAnswerWithLinks = (text, references) => {
    return text; // ✅ Just return the text, ignoring references
  };
  
  
  
  
  

  return (
    <div className={`command-panel flex flex-col items-center justify-center px-4 py-2 bg-white h-full ${className}`}>

      {/* Show "KHORN AI" when no messages */}
      {messages.length === 0 && (
        <h1 className="text-4xl font-bold text-black text-center mb-5">วันนี้คุณอยากให้ช่วยหากฎหมายหรือคดีอะไร?</h1>
        
      )}
      {/* Chat Container (Dynamically Expands) */}
      <div
        className={`flex flex-col w-full max-w-3xl border rounded-3xl bg-white shadow-md transition-all duration-500
          ${messages.length === 0 ? "h-auto min-h-[30vh] justify-center" : "h-full"}
        `} 
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >

      {isDragOver && (
        <div className="absolute inset-0 flex items-center justify-center text-blue-500 font-semibold text-lg bg-blue-50 bg-opacity-50">
          Drop your file here
        </div>
      )}
  {/* 🧭 Mode Selection Tabs */}
  <div className="flex justify-center gap-4 py-2 border-gray-200 bg-white sticky top-0 z-10">
    {["chat", "search", "draft", "review"].map((mode) => (
      <button
        key={mode}
        className={`px-4 py-1 rounded-full text-sm font-medium border transition-all duration-200 ${
          selectedMode === mode
            ? "bg-black text-white border-black"
            : "bg-white text-gray-600 border-gray-300 hover:bg-gray-100"
        }`}
        onClick={() => setSelectedMode(mode)}
      >
         {mode === "chat" && "💬 ถามตอบทั่วไป"}
        {mode === "search" && "🔍 ค้นหากฎหมาย"}
        {mode === "draft" && "✍️ ร่างเอกสาร"}
        {mode === "review" && "🧐 ตรวจและแก้ไข"}
      </button>
    ))}
  </div>


{/* 🧾 Draft Mode UI Toggle */}
{/* 🧾 Show only when user selects Draft */}

<div className={`transition-all duration-500 ease-in-out transform ${
  selectedMode === "draft" ? "opacity-100 translate-y-0 max-h-40" : "opacity-0 -translate-y-2 max-h-0 overflow-hidden"
}`}>
  <div className="flex justify-center gap-3 py-2">
    {/* Free-form button */}
    <button
      className={`px-4 py-1 rounded-full text-sm font-medium border ${
        draftType === "free-form"
          ? "bg-black text-white border-black"
          : "bg-white text-gray-600 border-gray-300 hover:bg-gray-100"
      }`}
      onClick={() => {
        setDraftType("free-form");
        setShowFormDropdown(false);
        setSelectedFormTemplate("");
      }}
    >
      ✍️ ร่างแบบทั่วไป
    </button>

    {/* Form-fill toggle/dropdown */}
    <div className="relative">
      <button
        className={`px-4 py-1 rounded-full text-sm font-medium border ${
          draftType === "form-fill"
            ? "bg-black text-white border-black"
            : "bg-white text-gray-600 border-gray-300 hover:bg-gray-100"
        }`}
        onClick={() => {
          setDraftType("form-fill");
          setShowFormDropdown(!showFormDropdown);
        }}
      >
        {selectedFormTemplate
          ? `🧾 ร่างแบบฟอร์ม: ${selectedFormTemplate}`
          : "🧾 ร่างแบบฟอร์ม"}
      </button>

      {showFormDropdown && (
        <div className="absolute z-10 mt-1 bg-white border border-gray-300 rounded shadow-md w-48">
        {["คำฟ้อง", "คำร้อง", "สัญญากู้ยืมเงิน", "สัญญาเช่า", "คำขอรับชำระหนี้", "คำให้การ"].map((form) => (
          <button
            key={form}
            className={`block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 flex justify-between items-center ${
              form === "คำฟ้อง" ? "text-black" : "text-gray-400 cursor-not-allowed"
            }`}
            onClick={() => {
              if (form === "คำฟ้อง") {
                setSelectedFormTemplate(form);
                setShowFormDropdown(false);
              }
            }}
            disabled={form !== "คำฟ้อง"} // Disable other forms
          >
            <span>{form}</span>
            {form !== "คำฟ้อง" && (
              <span className="flex items-center gap-1 text-xs text-gray-500">
                <Lock size={14} /> เร็วๆนี้
              </span>
            )}
          </button>
        ))}
      </div>
      )}
    </div>
  </div>
</div>


<div className="flex flex-col flex-grow p-4 overflow-y-auto">
  
  {messages.map((msg, index) => (
    <div key={index} className={`p-2 my-1 rounded-md ${
      msg.sender === "user"
        ? "bg-blue-100 ml-4 text-sm text-blue-800 self-end"
        : "bg-white mr-4 text-sm text-gray-800 self-start"
    }`}>
      {msg.sender === "bot" && msg.subqueries && msg.subqueries.length > 0 ? (
        <>
          <div className="flex justify-between">
            <p
              onClick={() =>
                setMessages((prevMessages) =>
                  prevMessages.map((m) =>
                    m.id === msg.id
                      ? { ...m, expanded: !m.expanded }
                      : m
                  )
                )
              }
              className={`cursor-pointer font-bold transition-all duration-300 ${
                thinkingMessageId == msg.id ? "text-gray-500 shiny-text" : "opacity-100"
              }`}
            >
              {msg.expanded ? "▼ ปิดขั้นตอนการวิเคราะห์" : "▶ แสดงขั้นตอนการวิเคราะห์"}
            </p>
          </div>

          {msg.expanded && (
            <div className="border-l-2 border-stone-100 pl-3 ml-4">
              {msg.subqueries.map((subMsg, subIndex) => (
                <div
                  key={subIndex}
                  className={`p-2 mt-1 rounded-md transition-opacity duration-500 ${
                    msg.id === thinkingMessageId ? "shiny-text" : "opacity-100"
                  }`}
                >
                  <p
                    className="font-bold cursor-pointer"
                    onClick={() =>
                      setMessages((prevMessages) =>
                        prevMessages.map((m) =>
                          m.id === msg.id
                            ? {
                                ...m,
                                subqueries: m.subqueries.map((s, idx) =>
                                  idx === subIndex ? { ...s, expanded: !s.expanded } : s
                                ),
                              }
                            : m
                        )
                      )
                    }
                  >
                    {subMsg.expanded ? "▼ " : "▶ "}{subMsg.text}
                  </p>

                  {subMsg.expanded && (
                    <p
                      className={`border-l-2 border-stone-100 pl-3 text-sm transition-all text-gray-600 duration-500 ${
                        subMsg.loading ? "animate-pulse" : "animate-fade-in"
                      }`}
                      
                      dangerouslySetInnerHTML={{
                        __html: subMsg.loading
                          ? "⏳ กำลังคิด..."
                          : legacyFormatMessage(subMsg.response),
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          )}

          {/* Final Answer Section */}
          {msg.finalAnswer && (
            <div className="mt-4 p-4 transition-opacity duration-500">
              <div className="mt-2 leading-relaxed">

          {thinkingMessageId === msg.id ? (
                  <Typewriter
                    options={{
                      delay: 1, // Speed of typing
                      cursor: "", // Typing cursor
                    }}
                    onInit={(typewriter) => {
                      typewriter
                        .typeString(legacyFormatMessage(msg.finalAnswer))
                        .start()
                        .callFunction(() => {
                          setThinkingMessageId(null); // ✅ Stop animation after typing finishes
                        });
                    }}
                  />
                ) : (
                  <p dangerouslySetInnerHTML={{ 
                    __html: formatFinalAnswerWithLinks(legacyFormatMessage(msg.finalAnswer), msg.references) 
                  }} 
                  />
                )}
                {/* ✅ Render the Legal Analysis Table below the final answer */}
                {msg.legalAnalysisTable && msg.legalAnalysisTable.length > 0 && (
                  <div className="mt-6 overflow-x-auto w-full">
                  <LegalAnalysisTable data={msg.legalAnalysisTable} />
                </div>
                
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: legacyFormatMessage(msg.text) }} />

      )}
    </div>
  ))}
   <div ref={chatEndRef}></div> 
</div>



      {/* Uploaded File Display */}
      {uploadedFiles && uploadedFiles.length > 0 && (
      <div className="space-y-2">
        {uploadedFiles.map((file, index) => (
          <div key={index} className="uploaded-file-info mx-3 p-2 bg-green-50 border border-green-200 rounded-md flex items-center justify-between">
            <div className="flex items-center gap-2">
              <FileText size={18} className="text-green-600" />
              <span className="text-green-800 font-medium">{file.name}</span>
            </div>
            <button onClick={() => onClearFile(index)} className="text-red-500 hover:text-red-700">
              <X size={18} />
            </button>
          </div>
        ))}
      </div>
    )}



{/* Input & Actions */}
<div className="p-3 flex flex-col gap-2 w-full max-w-3xl">
{messages.length === 0 && (
<div className="flex flex-col items-center w-full">
  <p className="text-center text-gray-600 text-sm animate-fade-in">
    ✨ ลองใช้คำแนะนำเหล่านี้เพื่อเริ่มต้น
  </p>

  {/* ✅ Centered Message Suggestions */}
  <div className="grid grid-cols-3 gap-2 w-full mx-auto">
    {["การนำเพลงที่มีลิขสิทธ์มาใช้ในการโฆษณาจะถือว่าละเมิดลิขสิทธ์มั้ย", "การฉ้อโกงและปลอมแปลงเอกสารต่างกันอย่างไร โทษต่างกันอย่างไร", "ทำร้ายร่างกายผิดอย่างไร ระดับในการทำร้ายร่างกายแบ่งอย่างไรได้บ้าง"].map((suggestion, index) => (
      <button
        key={index}
        className={`px-1 h-12 w-full text-xs rounded-xl flex items-center justify-center transition-all duration-300
          ${isSending 
            ? "bg-stone-300 text-gray-500 cursor-not-allowed" 
            : "bg-gray-100 text-black hover:bg-stone-300 border shadow-sm "
          }`}
        onClick={() => {
          if (!isSending) {
            setInput(suggestion); // ✅ Set input
          }
        }}
        disabled={isSending} // ✅ Disable when sending
      >
        {suggestion}
      </button>
    ))}
  </div>
  </div>
)}
  



  {/* ✅ Input Row (Voice, Web Search, Upload, Text Input, Send Button) */}
  <div className="flex items-center gap-2">
    
    {/* Upload and Voice Input */}
    <div className="flex items-center gap-2">
      <button onClick={() => alert("Voice input coming soon!")}
        className="flex items-center gap-2 p-3 bg-white rounded-md hover:bg-stone-300">
        <AudioLines size={18} /> 
      </button>
      <button onClick={() => alert("Web Search coming soon!")}
        className="flex items-center gap-2 p-3 bg-white rounded-md hover:bg-stone-300">
        <Globe size={18} /> 
      </button>
      <label className="flex items-center gap-2 p-3 bg-white rounded-md hover:bg-stone-300 cursor-pointer">
        <Upload size={18} /> 
        <input
          type="file"
           multiple
          onChange={handleFileSelect}
          className="hidden"
          accept=".txt,.png,.jpg,.pdf,.doc,.docx"
        />
      </label>
    </div>

    {/* ✅ Chat Input with "Shift + Enter" for new line & Auto-expanding height */}
    <textarea
  value={input}
  rows={1} // ✅ Ensures the smallest starting height
  onChange={(e) => {
    setInput(e.target.value);
    e.target.style.height = "auto"; // ✅ Reset before measuring
    e.target.style.height = `${e.target.scrollHeight}px`; // ✅ Adjust dynamically
  }}
  onKeyDown={(e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (!isSending) {
        handleSendMessage();
      }
    }
  }}
  placeholder="สอบถามกฎหมาย หรือช่วยร่างเอกสารได้ที่นี่..."
  className="flex-1 p-2 border border-gray-300 rounded-xl outline-none resize-none max-h-48 overflow-y-auto"
  style={{ height: "auto", boxSizing: "border-box" }} // ✅ Ensures correct height calculation
/>



    {/* ✅ Send Button */}
    <button
      onClick={handleSendMessage}
      disabled={!input.trim() || isSending}
      className={`h-12 px-4 text-sm rounded-xl flex items-center justify-center ${
        !input.trim() || isSending
          ? "bg-stone-300 text-gray-500 cursor-not-allowed"
          : "bg-black text-white hover:bg-stone-800"
      }`}
    >
      <ArrowUp size={18} />
    </button>

  </div>
</div>



    </div>
  </div>
);
};

export default CommandPanel;


