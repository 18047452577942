import React, { useState } from "react";

import { Loader2,Eye, EyeOff } from "lucide-react";
import { useLocation,useNavigate } from "react-router-dom";
import axios from "./axiosConfig";
import { motion } from "framer-motion"

export default function SignupPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { plan = "free", isYearly = true } = location.state || {};

  const plans = {
    free: {
      name: "ฟรี",
      features: [
        "1 ผู้ใช้งาน",
        "KHORN AI (จำกัดการใช้งาน)",
        "1 โครงการ",
        "การสนับสนุนพื้นฐาน"
      ],
      
      price: "ฟรี",
    },
    pro: {
      name: "Pro",
      features: [
        "1 ผู้ใช้งาน",
        "โครงการไม่จำกัด",
        "KHORN AI (ไม่จำกัดการใช้งาน)",
        "KHORN Research Agent",
        "KHORN Review Agent",
        "บริการสนับสนุนตลอด 24 ชั่วโมง",
      ],
      yearlyPrice: 5388,
    },
  };

  const selectedPlan = plans[plan] || plans["free"];

  const [form, setForm] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "", 
    plan,
    isYearly,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [isFadingOut,setIsFadingOut] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);



  const isPasswordSecure = (password) => {
    const minLength = /.{8,}/;
    const upperCase = /[A-Z]/;
    const lowerCase = /[a-z]/;
    const number = /[0-9]/;
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/;
  
    return (
      minLength.test(password) &&
      upperCase.test(password) &&
      lowerCase.test(password) &&
      number.test(password) &&
      specialChar.test(password)
    );
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "phone") {
      // 🧹 Clean phone: remove non-digits, remove +66 or 66 prefix
      let numericOnly = value.replace(/\D/g, "");
      if (numericOnly.startsWith("66")) {
        numericOnly = numericOnly.slice(2);
      }
  
      // Limit to 10 digits (optional)
      if (numericOnly.length > 10) {
        numericOnly = numericOnly.slice(0, 10);
      }
  
      setForm((prev) => ({ ...prev, [name]: numericOnly }));
      return;
    }
  
    setForm((prev) => ({ ...prev, [name]: value }));
  
    if (name === "confirmPassword") {
      setIsPasswordValid(isPasswordSecure(value));
      setPasswordsMatch(value === form.password);
    }
    if (name === "password") {
      setPasswordsMatch(value === form.confirmPassword);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
  
    if (!passwordsMatch) {
      setErrorMessage("Passwords do not match.");
      setIsLoading(false);
      return;
    }

    if (form.phone.length !== 10) {
      setErrorMessage("กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก (เช่น 0812345678)");
      setIsLoading(false);
      return;
    }
    if (!isPasswordSecure(form.password)) {
      setErrorMessage("รหัสผ่านไม่ปลอดภัยพอ กรุณาทำตามคำแนะนำด้านล่าง");
      setIsLoading(false);
      return;
    }
    
  
    try {
      if (form.plan === "free") {
        // For free plans
        const response = await axios.post("/api/auth/signup", {
          username: form.username,
          email: form.email,
          phone: form.phone,
          password: form.password,
          account_type: form.plan,
        });
  
        // setSuccessMessage("Sign up successful! Check your email for instructions.");
        // window.location.href = "https://app.khorn-ai.com/auth";
        // **Trigger animation before navigating**
        setIsFadingOut(true);
        setTimeout(() => {
            navigate("/"); // Navigate to main page after signup
        }, 500);
      } else {
        // For paid plans
        const response = await axios.post("/create-purchase", {
          username: form.username,
          email: form.email,
          phone: form.phone,
          password: form.password,
          plan: form.plan,
          subscription_commitment: form.isYearly ? "yearly" : "monthly", // Add subscription commitment
        });
  
        // Redirect to the payment link provided by the API
        window.location.href = response.data.paymentLink;
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.detail || "An error occurred.");
    } finally {
      setIsLoading(false);
    }
  };
  
  const getPlanPrice = () => {
    if (form.plan === "free") {
      return "Free";
    }
  
    const planDetails = plans[form.plan]; // Retrieve plan details from the `plans` object
    if (!planDetails) {
      return "Free Plan"; // Fallback for invalid plans
    }
  
    const yearlyPrice = planDetails.yearlyPrice;
    const monthlyPrice = planDetails.monthlyPrice.toLocaleString();
  
    // Determine what to display based on form.isYearly
    if (form.isYearly) {
      const yearlyPriceText = `${yearlyPrice.toLocaleString()}.00 THB / year`;
      const monthlyEquivalent = (yearlyPrice / 12).toLocaleString(undefined, { minimumFractionDigits: 2 });
      const monthlyEquivalentText = `${monthlyEquivalent} THB x 12 month`;
      
      return (
        <>
          {yearlyPriceText}
          <br />
          {monthlyEquivalentText}
        </>
      );
    } else {
      return `${monthlyPrice} THB / month`;
    }
  };
  
  
  
  

  return (
<motion.div
  initial={{ opacity: 0, filter: "blur(5px)" }}
  animate={isFadingOut ? { opacity: 0.5, filter: "blur(5px)" } : { opacity: 1, filter: "blur(0px)" }}
  exit={{ opacity: 0, filter: "blur(5px)" }}
  transition={{ duration: 0.8 }}
>
    <div style={{ backgroundColor: "#f0f4f8", minHeight: "100vh", padding: "40px 20px" }}>
      <div
        style={{
          maxWidth: "900px",
          margin: "0 auto",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          padding: "30px",
        }}
      >
        <div style={{ textAlign: "center", marginBottom: "30px" }}>
          <h2 style={{ fontSize: "28px", fontWeight: "bold", color: "#333" }}>
            สมัครทดลองใช้งานแผน {selectedPlan.name} 
          </h2>
        </div>

        <div style={{ display: "flex", gap: "20px" }}>
          <div style={{ flex: 1, backgroundColor: "#fff", borderRadius: "8px", padding: "20px" }}>
            <h3 style={{ fontSize: "18px", fontWeight: "600", marginBottom: "10px", color: "#333" }}>รายละเอียดแผน</h3>
            <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "10px" }}>
              {selectedPlan.features.map((feature, index) => (
                <li
                  key={index}
                  style={{
                    fontSize: "14px",
                    color: "#555",
                    marginBottom: "8px",
                    padding: "5px 10px",
                    borderLeft: "4px solid #000",
                    backgroundColor: "#f9fafb",
                    borderRadius: "4px",
                  }}
                >
                  {feature}
                </li>
              ))}
              <li
                style={{
                  fontSize: "14px",
                  color: "#333",
                  marginTop: "15px",
                  fontWeight: "bold",
                  padding: "10px",
                  borderTop: "1px solid #ccc",
                }}
              >
                ค่าใช้จ่าย: {getPlanPrice()}
              </li>
            </ul>
          </div>

          <div style={{ flex: 2 }}>
            <form onSubmit={handleSubmit}>
              <div style={{ marginBottom: "15px" }}>
                <label
                  htmlFor="username"
                  style={{
                    display: "block",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#333",
                    marginBottom: "5px",
                  }}
                >
                  ชื่อผู้ใช้ (Username)
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  value={form.username}
                  onChange={handleChange}
                  placeholder="Enter your username"
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>

              <div style={{ marginBottom: "15px" }}>
                <label
                  htmlFor="email"
                  style={{
                    display: "block",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#333",
                    marginBottom: "5px",
                  }}
                >
                  อีเมล์ที่ใช้สมัคร (Email)
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={form.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>
              <div style={{ marginBottom: "15px" }}>
              <label
                htmlFor="phone"
                style={{
                  display: "block",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#333",
                  marginBottom: "5px",
                }}
              >
                เบอร์โทรศัพท์ (Phone Number)
              </label>
              <input
                id="phone"
                name="phone"
                type="tel"
                value={form.phone}
                onChange={handleChange}
                placeholder="Enter your phone number"
                required
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              />
            </div>
            <div style={{ position: "relative", marginBottom: "15px" }}>
            <label
                  htmlFor="password"
                  style={{
                    display: "block",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#333",
                    marginBottom: "5px",
                  }}
                >
                  รหัสผ่าน (Password)
                </label>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"} // ✅ Dynamically change type
                  value={form.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
                 <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "40px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  color: "#000",
                  fontSize: "12px",
                }}
              >
                    {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
              </button>
              </div>
              {form.password && !isPasswordValid && (
              <ul style={{ fontSize: "12px", color: "red", marginTop: "5px", paddingLeft: "20px" }}>
                <li>รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร</li>
                <li>ต้องมีตัวพิมพ์ใหญ่ (A-Z)</li>
                <li>ต้องมีตัวพิมพ์เล็ก (a-z)</li>
                <li>ต้องมีตัวเลข (0-9)</li>
                <li>ต้องมีอักขระพิเศษ (!@#$...)</li>
              </ul>
            )}


              <div style={{ marginBottom: "15px" }}>
                <label
                  htmlFor="confirmPassword"
                  style={{
                    display: "block",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#333",
                    marginBottom: "5px",
                  }}
                >
                  ยืนยันรหัสผ่าน (Confirm Password)
                </label>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showPassword ? "text" : "password"} // ✅ Dynamically change type
                  value={form.confirmPassword}
                  onChange={handleChange}
                  placeholder="Confirm your password"
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
                {!passwordsMatch && <p style={{ color: "red", fontSize: "12px" }}>รหัสผ่านไม่ตรงกัน โปรดตรวจสอบอีกครั้ง</p>}
             
              </div>
              
              

              {/* Consent Checkbox */}
              <div style={{ marginBottom: "15px" }}>
                <label style={{ display: "flex", alignItems: "center", fontSize: "14px", color: "#333" }}>
                  <input
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    checked={isConsentChecked}
                    onChange={(e) => setIsConsentChecked(e.target.checked)}
                    required
                  />
                  ฉันยอมรับ{" "}
                  <a
                    href="/privacy-policy"
                    style={{
                      color: "#007bff",
                      textDecoration: "underline",
                      marginLeft: "5px",
                    }}
                  >
                    นโยบายความเป็นส่วนตัว
                  </a>
                </label>
              </div>

              <button
                type="submit"
                style={{
                  width: "100%",
                  padding: "12px",
                  backgroundColor: "#000",
                  color: "#fff",
                  borderRadius: "4px",
                  fontWeight: "bold",
                  cursor: isConsentChecked ? "pointer" : "not-allowed",
                  fontSize: "16px",
                }}
                disabled={!isConsentChecked || isLoading}
              >
                {isLoading ? <Loader2 className="h-5 w-5 animate-spin" /> : "สมัคร"}
              </button>

              {errorMessage && <p style={{ color: "red", marginTop: "15px", textAlign: "center" }}>{errorMessage}</p>}
              {successMessage && <p style={{ color: "green", marginTop: "15px", textAlign: "center" }}>{successMessage}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
    </motion.div>
  );
}
