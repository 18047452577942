import React from "react";
import SubTab from "./SubTab";
import DraftOutput from "./DraftOutput";
import FilesOutput from "./FilesOutput";
import SkeletonLoader from "./SkeletonLoader";
import DataOutput from "./DataOutput";


const ActionPanel = ({
  projectId, // ✅ Receive projectId from AppLayout
  drafts,
  onUpdateDraft,
  loading,
  internalDocuments,
  setInternalDocuments, // ✅ Receive setter from AppLayout
  className,
  activeSubTab,
  setActiveSubTab,
  extractedData,
  activeDraft,
  setActiveDraft,
  selectedMode
  
}) => {
  console.log("Internal Documents:", internalDocuments);
  console.log("extractedData", extractedData);
  console.log("onUpdateDraft:", onUpdateDraft);
  console.log("Drafts:", drafts);

    // **Check if there's no data available at all**
    const noData =
    (drafts.length === 0) && // ✅ Use drafts instead
    (!internalDocuments || internalDocuments.length === 0) &&
    (!extractedData || Object.keys(extractedData).length === 0);



    


  return (
    
    <div className={`action-panel flex flex-col bg-black shadow-md h-full ${className}`}>


      {/* Subtabs */}
      <SubTab
        activeSubTab={activeSubTab}
        setActiveSubTab={setActiveSubTab}
      />

        {/* Content */}
        <div className="subtab-content flex-1 p-4 overflow-y-auto bg-white">
        {loading && noData? (
          <SkeletonLoader type={activeSubTab === "Output" ? "Output" : "References"} />
        ) : activeSubTab === "Output" ? (
        <DraftOutput 
        drafts={drafts}  // ✅ Use drafts directly
        onUpdateDraft={onUpdateDraft}  
        activeDraft={activeDraft}         // ✅ <-- Add this
        setActiveDraft={setActiveDraft}   // ✅ <-- Add this
        loading={loading} 
        selectedMode={selectedMode} // ✅ pass this prop
        projectId={projectId} // ✅ Make sure this is passed!
        />

        ) : 
        activeSubTab === "References" ? (
          <FilesOutput 
            projectId={projectId} 
            internalDocuments={internalDocuments} 
            setInternalDocuments={setInternalDocuments} 
            loading={loading} 
          />
        )  : null}

      </div>
    </div>
  );
};

export default ActionPanel;


