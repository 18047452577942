import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import axios from "../axiosConfig";

const ReferenceLibrary = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [documents, setDocuments] = useState({});
  const [expandedDocument, setExpandedDocument] = useState(null);
  const [expandedBook, setExpandedBook] = useState(null);
  const [expandedSection, setExpandedSection] = useState(null);

  useEffect(() => {
    fetchReferences();
  }, []);

  const fetchReferences = async () => {
    try {
      const response = await axios.get("/api/references");

      // ✅ Group documents by name, book, and section
      const groupedDocs = response.data.reduce((acc, doc) => {
        const docName = doc.document_name;
        const book = doc.book;
        const section = doc.section;

        if (!acc[docName]) acc[docName] = {};
        if (!acc[docName][book]) acc[docName][book] = {};
        
        // If section does not exist, create it
        if (!acc[docName][book][section]) {
          acc[docName][book][section] = {
            article: new Set(),
            details: new Set(),
            page_number: new Set(),
            reference_articles: new Set(),
            notes: new Set()
          };
        }

        // Add values while ensuring uniqueness
        acc[docName][book][section].article.add(doc.article);
        if (doc.details) acc[docName][book][section].details.add(doc.details);
        if (doc.page_number) acc[docName][book][section].page_number.add(doc.page_number);
        if (doc.reference_articles) acc[docName][book][section].reference_articles.add(doc.reference_articles);
        if (doc.notes) acc[docName][book][section].notes.add(doc.notes);

        return acc;
      }, {});

      // Convert Sets to strings for display
      Object.keys(groupedDocs).forEach((docName) => {
        Object.keys(groupedDocs[docName]).forEach((book) => {
          Object.keys(groupedDocs[docName][book]).forEach((section) => {
            const sectionData = groupedDocs[docName][book][section];

            groupedDocs[docName][book][section] = {
              article: [...sectionData.article].join(", "),
              details: [...sectionData.details].join(" "),
              page_number: [...sectionData.page_number].join(", "),
              reference_articles: [...sectionData.reference_articles].join(", "),
              notes: [...sectionData.notes].join(" "),
            };
          });
        });
      });

      setDocuments(groupedDocs);
    } catch (error) {
      console.error("Error fetching references:", error);
    }
  };

  return (
    <div className="flex h-screen">
      <Sidebar isOpen={isSidebarOpen} />

      {/* Main Content */}
      <div className="flex-1 p-6 overflow-y-auto bg-gray-50">
        <h1 className="text-2xl font-bold mb-4">📚 Reference Library</h1>

        {/* Document List */}
        <div className="space-y-4">
          {Object.keys(documents).length === 0 ? (
            <p className="text-gray-500 text-center">⚠️ No documents found.</p>
          ) : (
            Object.keys(documents).map((docName) => (
              <div key={docName} className="bg-white p-4 rounded-lg shadow-md">
                {/* Document Name - Click to Expand */}
                <div
                  className="cursor-pointer font-bold text-lg p-2 bg-gray-100 hover:bg-gray-200 rounded-md"
                  onClick={() => setExpandedDocument(expandedDocument === docName ? null : docName)}
                >
                  📖 {docName}
                </div>

                {/* Books inside Document */}
                {expandedDocument === docName && (
                  <div className="mt-2 pl-4 space-y-2">
                    {Object.keys(documents[docName]).map((book) => (
                      <div key={book}>
                        <div
                          className="border p-2 rounded-md bg-gray-100 hover:bg-gray-200 cursor-pointer"
                          onClick={() => setExpandedBook(expandedBook === book ? null : book)}
                        >
                          📚 {book}
                        </div>

                        {/* Sections inside Book */}
                        {expandedBook === book && (
                          <div className="mt-2 pl-4 space-y-2">
                            {Object.keys(documents[docName][book]).map((section) => {
                              const doc = documents[docName][book][section];
                              return (
                                <div key={section}>
                                  <div
                                    className="p-2 border rounded-md bg-gray-200 hover:bg-gray-300 cursor-pointer"
                                    onClick={() => setExpandedSection(expandedSection === section ? null : section)}
                                  >
                                    📌 มาตรา {section}
                                  </div>

                                  {/* Article Details */}
                                  {expandedSection === section && (
                                    <div className="mt-2 p-3 bg-white border rounded-md">
                                      <p><strong>📜 Article:</strong> {doc.article}</p>
                                      <p><strong>📝 Details:</strong> {doc.details || "-"}</p>
                                      <p><strong>📄 Page(s):</strong> {doc.page_number || "-"}</p>
                                      <p><strong>🔗 References:</strong> {doc.reference_articles || "-"}</p>
                                      <p><strong>📝 Notes:</strong> {doc.notes || "-"}</p>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ReferenceLibrary;
