import React from "react";
import { Check } from "lucide-react";
import { useNavigate } from "react-router-dom";

export default function PlanPage() {
  const navigate = useNavigate();

  const plans = [
    {
      name: "Free",
      planKey: "free",
      commitment: null,
      price: "ทดลองใช้งานฟรี",
      description: "ทดลองใช้งาน",
      features: [
        "1 ผู้ใช้งาน",
        "1 โครงการ",
        "KHORN",
        "การสนับสนุนพื้นฐาน",
      ],
    },
    {
      name: "Pro (1 เมษายน 2025)",
      planKey: "pro",
      commitment: "monthly",
      price: "",
      description: "สำหรับทนายยุคใหม่",
      features: [
        "1 ผู้ใช้งาน",
        "โครงการไม่จำกัด",
        "KHORN AI (ไม่จำกัดการใช้งาน)",
        "KHORN Research Agent",
        "KHORN Review Agent",
        "บริการสนับสนุนตลอด 24 ชั่วโมง",
      ],
    },
  ];

  const handleSelectPlan = (planKey, commitment) => {
    navigate("/signup", {
      state: { plan: planKey, isYearly: commitment === "yearly" },
    });
  };

  return (
<div className="py-12 bg-white flex justify-center">
    <div className="w-full max-w-3xl mx-auto px-6 sm:px-8 lg:px-12">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
           ทดลองใช้งาน KHORN ฟรีได้เลย
          </h2>

          <p className="mt-2 text-xl text-gray-500 font-semibold">
          เตรียมตัวให้พร้อม KHORN PRO จะให้้ใช้งาน 1 เมษายนนี้!
          </p>
        </div>

        <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 gap-6"> 
            {plans.map((plan) => (
                <div
                key={`${plan.planKey}-${plan.commitment || "free"}`}
                className={`relative flex flex-col justify-between border rounded-lg shadow-md p-6
                transition-all duration-300 ease-in-out delay-70 hover:bg-black hover:text-white group
                ${plan.highlight ? "border-green-600 shadow-lg" : ""}`}
              >
  <div className="mb-4">
    <h3 className="text-2xl font-semibold group-hover:text-white">{plan.name}</h3>
    <p className="text-sm text-gray-600 group-hover:text-gray-300">{plan.description}</p>
  </div>

  <div className="text-center mb-4">
    <span className="text-4xl font-extrabold group-hover:text-white">{plan.price}</span>
    {plan.commitment && (
      <span className="text-base font-medium text-gray-500 group-hover:text-gray-300">

      </span>
    )}
    {plan.totalYearly && (
      <p className="mt-2 text-sm text-gray-600 group-hover:text-gray-300">
        Total Yearly:{" "}
        <span className="font-bold text-gray-800 group-hover:text-white">
          {plan.totalYearly} THB
        </span>
      </p>
    )}
  </div>

  <ul className="mb-6 space-y-2">
    {plan.features.map((feature) => (
      <li key={feature} className="flex items-center text-gray-700 group-hover:text-white">
        <Check className="h-5 w-5 text-black group-hover:text-white mr-2" />
        <span>{feature}</span>
      </li>
    ))}
  </ul>

  <button
  onClick={() => handleSelectPlan(plan.planKey, plan.commitment)}
  disabled={plan.name !== "Free"} // Disable if not free
  className={`w-full px-4 py-2 text-white rounded-md shadow-sm transition-all duration-300 border-2 border-white
    ${plan.name === "Free" ? "bg-black hover:bg-white hover:text-black cursor-pointer" : "bg-gray-400 opacity-50 cursor-not-allowed"}
  `}
>
  {plan.name === "Free" ? "สมัครใช้งานฟรี" : "Coming Soon"}
</button>


  {plan.highlight && (
    <div className="absolute top-0 right-0 px-3 py-1 bg-green-500 text-white text-sm font-semibold rounded-bl rounded-tr">
      Save 50%
    </div>
  )}
</div>

          ))}
        </div>
      </div>
    </div>
  );
}
