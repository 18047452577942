import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from './axiosConfig';
import AuthPage from './AuthPage';
import AppLayout from './AppLayout';
import PlanPage from './PlanPage'; // Import your new PlanPage component
import SignupPage from './SignupPage';
import PrivacyPolicy from "./PrivacyPolicy";
import AccountPage from './AccountPage';
import VaultPage from './components/VaultPage';
import { AnimatePresence } from "framer-motion"; // page animation
import UserMessage from './components/UserMessage';
import ReferencesLibrary from './components/ReferenceLibrary';
import WorkshopPage from './WorkshopPage';

function isTokenExpired(token) {
  try {
    const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decode JWT
    const currentTime = Date.now() / 1000; // Convert to seconds
    return decodedToken.exp < currentTime;
  } catch (error) {
    return true; // If token is invalid, treat it as expired
  }
}



function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token && !isTokenExpired(token)) {
      axios
        .get('/api/auth/verify', { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          if (response.data.status === 'valid') {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        })
        .catch(() => {
          setIsAuthenticated(false);
        })
        .finally(() => setLoading(false));
    } else {
      localStorage.removeItem('authToken');
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, []);


  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
       <AnimatePresence mode="wait">
      <Routes>
        {/* Redirect to AuthPage if not authenticated */}
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/workshop" element={<WorkshopPage />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/plan" element={<PlanPage />} /> 
        <Route path="/vault" element={<VaultPage />} /> 
        <Route path="/reference" element={<ReferencesLibrary />} /> 
        <Route path="/user" element={<UserMessage />} /> 
        {/* ✅ Project Chat Route */}
        <Route path="/project/:projectId" element={isAuthenticated ? <AppLayout /> : <Navigate to="/auth" />} />
        {/* ✅ Default Main App Layout */}
        <Route path="/" element={isAuthenticated ? <AppLayout /> : <Navigate to="/auth" />} />        <Route path="/account" element={isAuthenticated ? <AccountPage /> : <Navigate to="/auth" />} />
      </Routes>
      </AnimatePresence>
    </Router>

  );
}

export default App;
