import React, { useState, useEffect } from "react";
import axios from "../axiosConfig";

const UserMessages = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedMessages, setExpandedMessages] = useState({});

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get("api/users");
      const sortedUsers = response.data.sort(
        (a, b) => new Date(b.signup_datetime) - new Date(a.signup_datetime) // Newest users first
      );
      setUsers(sortedUsers);
      setSelectedUser(sortedUsers.length > 0 ? sortedUsers[0] : null); // Auto-select first user
    } catch (error) {
      console.error("🚨 Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 max-w-8xl mx-auto ">
      <h1 className="text-2xl font-bold mb-4 text-center">📌 User Chat & Internal Documents</h1>

      {/* User Dropdown Selection */}
      <div className="flex justify-center mb-4">
        <select
          className="p-2 border rounded-lg bg-white shadow-sm"
          onChange={(e) => {
            const selected = users.find((user) => user.userId === e.target.value);
            setSelectedUser(selected);
          }}
          value={selectedUser?.userId || ""}
        >
          {users.map((user) => (
            <option key={user.userId} value={user.userId}>
              {user.username} ({user.email})
            </option>
          ))}
        </select>
      </div>

      {loading ? (
        <p className="text-center text-gray-500 animate-pulse">Loading user data...</p>
      ) : !selectedUser ? (
        <p className="text-center text-gray-500">No users found.</p>
      ) : (
        <div className="grid grid-cols-2 gap-6">
          {/* Left Column - Chat Messages */}
          <div className="border p-4 rounded-lg bg-gray-50 h-[600px] overflow-y-auto">
            <h4 className="text-md font-semibold mb-2">💬 Chat Messages</h4>
            <div className="space-y-3 mt-2">
              {selectedUser.messages.length > 0 ? (
                selectedUser.messages
                  .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)) // Oldest to newest
                  .map((msg, index) => (
                    <div
                      key={index}
                      className={`p-2 my-1 rounded-md ${
                        msg.sender === "user"
                          ? "bg-blue-100 ml-4 text-sm text-blue-800 self-end"
                          : "bg-white mr-4 text-sm text-gray-800 self-start"
                      }`}
                    >
                      {msg.sender === "bot" ? (
                        <>
                          <p className="font-bold">🤖 AI Response</p>
                          <p dangerouslySetInnerHTML={{ __html: msg.text }} />

                          {/* ✅ Show Subqueries (If Available) */}
                          {msg.subqueries && msg.subqueries.length > 0 && (
                            <div className="mt-2">
                              <p
                                className="font-bold cursor-pointer text-blue-500"
                                onClick={() =>
                                  setExpandedMessages((prev) => ({
                                    ...prev,
                                    [msg.id]: !prev[msg.id],
                                  }))
                                }
                              >
                                {expandedMessages[msg.id]
                                  ? "▼ Hide Subqueries"
                                  : "▶ Show Subqueries"}
                              </p>

                              {expandedMessages[msg.id] && (
                                <div className="border-l-2 border-gray-300 pl-3 mt-2">
                                  {msg.subqueries.map((subMsg, subIndex) => (
                                    <div key={subIndex} className="p-2 bg-gray-100 rounded-lg mt-1">
                                      <p className="font-bold">🔍 {subMsg.text}</p>
                                      <p dangerouslySetInnerHTML={{ __html: subMsg.response }} />
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          )}

                          {/* ✅ Show Final Answer (If Available) */}
                          {msg.finalAnswer && (
                            <div className="mt-4 p-4 bg-stone-200 rounded-lg shadow-md transition-opacity duration-500">
                              <p className="font-bold">📌 Final Answer</p>
                              <p dangerouslySetInnerHTML={{ __html: msg.finalAnswer }} />
                            </div>
                          )}
                        </>
                      ) : (
                        <p dangerouslySetInnerHTML={{ __html: msg.text }} />
                      )}

                      {/* ✅ Timestamp */}
                      <p className="text-xs text-gray-400 mt-1">
                        {new Date(msg.timestamp).toLocaleString()}
                      </p>
                    </div>
                  ))
              ) : (
                <p className="text-sm text-gray-500">No messages available.</p>
              )}
            </div>
          </div>

          {/* Right Column - Internal Documents */}
          <div className="border p-4 rounded-lg bg-gray-50 h-[600px] overflow-y-auto">
            <h4 className="text-md font-semibold mb-2">📄 Internal Documents</h4>
            <div className="space-y-3 mt-2">
              {selectedUser.internalDocuments.length > 0 ? (
                selectedUser.internalDocuments
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) // Newest first
                  .map((doc, index) => (
                    <div key={index} className="p-3 border rounded-lg bg-white shadow-sm">
                      <p className="text-sm font-bold">{doc.title}</p>
                      <p className="text-xs text-gray-500">{doc.content}</p>
                    </div>
                  ))
              ) : (
                <p className="text-sm text-gray-500">No documents available.</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserMessages;
