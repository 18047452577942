import React, { useState } from "react";
import axios from "./axiosConfig";

export default function WorkshopPage() {
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    selectedTime: "",
    company: "",  // ✅ Added
  });
  

  const [emailSent, setEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(0);


  const schedule = ["10:00 - 11:00", "13:00 - 14:00", "17:00 - 18:00"];

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      let numericOnly = value.replace(/\D/g, "");
      if (numericOnly.startsWith("66")) {
        numericOnly = numericOnly.slice(2);
      }
      if (numericOnly.length > 10) {
        numericOnly = numericOnly.slice(0, 10);
      }
      setForm((prev) => ({ ...prev, phone: numericOnly }));
    } else {
      setForm((prev) => ({ ...prev, [name]: value }));
    }
  };

  const sendWorkshopRequest = async (isResend = false) => {
    const { name, email, phone, selectedTime,company } = form;
    const scheduledMap = {
      "10:00 - 11:00": "2025-04-19T10:00:00.000Z",
      "13:00 - 14:00": "2025-04-19T13:00:00.000Z",
      "17:00 - 18:00": "2025-04-19T17:00:00.000Z",
    };
  
    try {
      setIsLoading(true);
      const response = await axios.post(`/api/auth/workshop_signup${isResend ? "?resend=true" : ""}`, {
        name,
        email,
        phone,
        company,
        scheduled_time: scheduledMap[selectedTime],
      });
  
      if (isResend) {
        alert("📩 ระบบได้ส่งอีเมลยืนยันอีกครั้งแล้ว กรุณาตรวจสอบอีเมลของคุณ");
      } else {
        setEmailSent(true);
      }
    } catch (error) {
      alert(error.response?.data?.detail || "เกิดข้อผิดพลาดในการลงทะเบียน");
    } finally {
      setIsLoading(false);
    }
  };
  
  
  const handleResend = async () => {
    await sendWorkshopRequest(true);
  
    // Start 30-second cooldown
    setResendCooldown(30);
    const interval = setInterval(() => {
      setResendCooldown((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };
  
  

  const handleSelectTime = (time) => {
    setForm((prev) => ({ ...prev, selectedTime: time }));
  };

  const handleSubmit = async () => {
    const { name, email, phone, selectedTime,company } = form;
  
    if (!name || !email || !phone || !selectedTime|| !company) {
      alert("กรุณากรอกข้อมูลให้ครบทุกช่อง");
      return;
    }
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("รูปแบบอีเมลไม่ถูกต้อง");
      return;
    }
  
    if (phone.length !== 10) {
      alert("กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก");
      return;
    }
  
    await sendWorkshopRequest(); // 🔁 extracted for reuse
  };
  


  return (
    <div style={{ backgroundColor: "#f0f4f8", minHeight: "100vh", padding: "40px 20px" }}>
      <div
        style={{
          maxWidth: "600px",
          margin: "0 auto",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          padding: "30px",
          textAlign: "center",
        }}
      >
        <h2 style={{ fontSize: "26px", fontWeight: "bold", marginBottom: "20px", color: "#333" }}>
          ลงทะเบียนเข้าร่วมเวิร์กช็อป KHORN AI
        </h2>

        {!emailSent && (
          <p style={{ marginBottom: "25px", color: "#666" }}>
            กรุณากรอกข้อมูลเพื่อเข้าร่วมในวันที่ <strong>19 เมษายน 2025</strong>
          </p>
        )}

        {emailSent ? (
          <>
          <p style={{ fontSize: "16px", color: "#007BFF", marginBottom: "10px" }}>
            ✅ เราได้ส่งอีเมลยืนยันการเข้าร่วมอบรมไปที่ <strong>{form.email}</strong> แล้ว
          </p>
          <p style={{ fontSize: "14px", color: "#555", marginBottom: "20px" }}>
            📅 กรุณาตรวจสอบอีเมลของคุณ จะมีไฟล์แนบ <strong>.ics</strong> ซึ่งสามารถเพิ่มนัดหมายลงในปฏิทินของคุณได้โดยอัตโนมัติ
          </p>

          <button
          onClick={handleResend}
          disabled={resendCooldown > 0}
          style={{
            padding: "10px 20px",
            borderRadius: "6px",
            backgroundColor: resendCooldown > 0 ? "#aaa" : "#000",
            color: "#fff",
            fontWeight: "bold",
            cursor: resendCooldown > 0 ? "not-allowed" : "pointer",
          }}
        >
          {resendCooldown > 0
            ? `กรุณารอ ${resendCooldown} วินาที... เพื่อส่งอีกครั้ง`
            : "หากไม่ได้รับอีเมล คลิกที่นี่เพื่อส่งอีกครั้ง"}
        </button>

          </>
        ) : (
          <>
            <div style={{ textAlign: "left", marginBottom: "20px" }}>
              <label style={labelStyle}>ชื่อ - นามสกุล</label>
              <input
                type="text"
                name="name"
                value={form.name}
                onChange={handleChange}
                placeholder="กรอกชื่อของคุณ"
                required
                style={inputStyle}
              />

              <label style={labelStyle}>อีเมล</label>
              <input
                type="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                placeholder="example@email.com"
                required
                style={inputStyle}
              />
              <label style={labelStyle}>ชื่อบริษัท / สำนักงานกฎหมาย</label>
              <input
                type="text"
                name="company"
                value={form.company}
                onChange={handleChange}
                placeholder="ชื่อบริษัทของคุณ"
                style={inputStyle}
              />


              <label style={labelStyle}>เบอร์โทรศัพท์</label>
              <input
                type="tel"
                name="phone"
                value={form.phone}
                onChange={handleChange}
                placeholder="0812345678"
                required
                style={inputStyle}
              />
            </div>

            <h4 style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "10px" }}>เลือกเวลาที่ต้องการ</h4>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginBottom: "25px" }}>
              {schedule.map((time, index) => (
                <button
                  key={index}
                  onClick={() => handleSelectTime(time)}
                  style={{
                    padding: "10px",
                    border: form.selectedTime === time ? "2px solid #000" : "1px solid #ccc",
                    borderRadius: "6px",
                    backgroundColor: form.selectedTime === time ? "#000" : "#fff",
                    color: form.selectedTime === time ? "#fff" : "#333",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  {time}
                </button>
              ))}
            </div>

            <button
              onClick={handleSubmit}
              disabled={isLoading}
              style={{
                width: "100%",
                padding: "12px",
                backgroundColor: "#000",
                color: "#fff",
                borderRadius: "6px",
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: "16px",
              }}
            >
              {isLoading ? "กำลังส่ง..." : "ยืนยันการเข้าร่วมเวิร์กช็อป"}
            </button>
          </>
        )}
      </div>
    </div>
  );
}

const inputStyle = {
  width: "100%",
  padding: "10px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  marginTop: "5px",
  marginBottom: "15px",
};

const labelStyle = {
  fontSize: "14px",
  fontWeight: "500",
  color: "#333",
};
