import React, { useEffect } from "react";
const SubTab = ({ activeSubTab, setActiveSubTab }) => {
  const tabs = [
    { id: "References", label: "ข้อมูลอ้างอิง" },
    { id: "Output", label: "เอกสารร่าง" },
    // { id: "DataOutput", label: "ข้อมูลโครงการ" },
  ];

  // ✅ Ensure "References" is selected first on mount
  useEffect(() => {
    if (!activeSubTab) {
      setActiveSubTab("References");
    }
  }, [activeSubTab, setActiveSubTab]);

  return (
    <div className="flex items-center bg-white px-4 py-2">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`px-3 py-2 rounded-md mx-2 font-medium transition-all ${
            activeSubTab === tab.id
              ? "bg-black text-white shadow-md"
              : "text-gray-600 hover:bg-gray-200"
          }`}
          onClick={() => setActiveSubTab(tab.id)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default SubTab;
